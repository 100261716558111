.Services {
    margin: 5% 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.Services-section {
    text-align: center;
    max-width: 800px;
    text-align: center;
    margin: 20px auto;
}

.Services-card {
    height: 584px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* filter: drop-shadow(0 0px 8px #1CB73585); */
    background-color: #FFFFFF;
    text-align: center;
}

.Services-card img {
    width: 100%;
}

.card-img {
    width: 100%;
}

.card-img .img1 {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.card-content {
    padding: 20px;
}

.card-content .mid {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.card-img {
    position: relative;
}

.card-img > .img2 {
    width: 132.96px;
    height: 118.55px;
    position: absolute;
    right: 0;
    bottom: -60px;
    margin-bottom: 30px;
}

.card-cover-img {
    width: 100%;
    height: 250px;
   
}
.card-cover-img > .img1{
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    object-position: top center !important;
}

.Servicesbtn {
    text-align: center;
}

.Servicesbtn button {
    padding: 12px 20px;
    margin-top: 20px;
}

.bannnar-img {
    width: 100%;
    height: 561px;
}

.bannnar-video-box {
    width: 100%;
    height: 400px;
    position: relative;
}
.bannnar-video-box  video{
    object-fit: cover;
}
.bannnar-video {
    width: 100%;
}

.rec.rec-arrow-right:hover {
    /* background-color: #1cb735 !important;
    color: white !important; */
    background-color: #fff !important;
    color: darkgrey !important;
}

.rec.rec-arrow-left:hover {
    /* background-color: #1cb735 !important;
    color: white !important; */
    background-color: #fff !important;
    color: darkgrey !important;
}

.rec.rec-arrow-right:focus {
    /* background-color: #1cb735 !important;
    color: white !important; */
    background-color: #fff !important;
    color: darkgrey !important;
}

.rec.rec-arrow-left:focus {
    /* background-color: #1cb735 !important;
    color: white !important; */
    background-color: #fff !important;
    color: darkgrey !important;
}

.rec.rec-arrow-right:disabled {
    color: darkgrey !important
}

.rec.rec-arrow-left:disabled {
    color: darkgrey !important
}

.rec.rec-arrow-right {
    /* background-color: #1cb735 !important;
    color: white !important; */
    background-color: #fff !important;
    color: #1cb735 !important;
}

.rec.rec-arrow-left {
    /* background-color: #1cb735 !important;
    color: white !important; */
    background-color: #fff !important;
    color: darkgrey !important;
}
/* .rec.rec-arrow-left ::before{
    content: "";
    background-image: url("../images/Path\ 501.png");
} */
.cNNJIt{
    box-sizing: border-box; 
    font-size: 5.6em !important;
    background-color: unset !important;
    color: unset !important;
    box-shadow: unset !important;
    border-radius: unset !important;
    border: none !important;
    padding: 0 !important;
    width: unset !important;
    height: unset !important;
    min-width: unset !important;
    line-height: unset !important;
}
.jRFJkP {
    box-sizing: border-box; 
    font-size: 5.6em !important;
    background-color: unset !important;
    color: unset !important;
    box-shadow: unset !important;
    border-radius: unset !important;
    border: none !important;
    padding: 0 !important;
    width: unset !important;
    height: unset !important;
    min-width: unset !important;
    line-height: unset !important;
}
.Services-img-box {
    width: 100%;
    height: 360px;
    /* background-color: aquamarine; */
}
.Services-img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* .bannar-section .container-fluid{
     padding:0 !important;
     border: 1px solid;
     max-width: 100%;
     margin: 0 !important;
 } */


/* .bannar-section
{
    background-image: url('../images/Group 580.png');
    background-size: cover;
    background-position: center ; 
 } */


/* 
@media screen and (max-width:1440px) {
    .Services-card {
        height: 500px;
    }
} */