.BlogMainSection {
  width: 100%;
  height: 100%;
}

.mainContainer {
  max-width: 90%;
}
.MiddleSection {
  padding: 96px 0px 16px 0px;
  min-height: calc(100vh - 548px);
}
.header {
  color: var(--text-color-black);
  margin-bottom: 20px;
}
.description {
  color: var(--text-dark-gray);
  margin-bottom: 20px;
  word-break: break-all;
}
.date {
  color: var(--text-dark-gray);
  margin-bottom: 45px;
}
.loaderBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 230px);
}
.blogDetailHeading{
  margin-bottom: 20px;
}
.blogDetailHeading h1{
  text-transform: capitalize;
}
.blogDetailHeading p{
  font-size: 18px;
}
.blogDetail_image{
  width: 100%;
  height: 600px;
  margin-bottom: 30px;
}
.blogDetail_image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.date_blogDetail{
  color: gray !important;
}
@media (min-width: 1440px) {
  .mainContainer {
    max-width: 80%;
  }
}
@media (max-width: 1200px) {
  .blogDetail_image{
    width: 95%;
    height: 400px;
    margin: auto;
  }
  .blogDetailHeading{
    width: 95%;
    margin: auto;
    margin-top: 20px;
  }
}
@media (max-width: 991px) {
  .MiddleSection {
    padding: 48px 0px 16px 0px;
    min-height: calc(100vh - 442px);
  }
}
@media (max-width: 768px) {
  .blogDetail_image{
    height: 400px;
  }
  .blogDetailHeading p{
    font-size: 14px;
  }
}
@media (max-width: 578px) {
  .header {
    font-size: 36px;
  }
  .blogDetail_image{
    height: 300px;
  }
}
@media (max-width: 420px) {
  .blogDetail_image{
    height: 200px;
  }
}
