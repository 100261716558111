.HowToOrderpage-section3{
    background-color: #1CB735;
    margin: 50px 0 0;
    padding: 50px 0;
    color: #fff;
}
.HowToOrderpage-section3-leftside{
   /* margin-top: 20%; */
}
.HowToOrderpage-btns button{
   padding: 10px 20px;
    border: 1px solid #fff;
}
.relax-man-img-box {
    width: 100%;
    height: 456px;
    display: flex;
    justify-content: flex-end;
}
.relax-man-img-box img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}