.rightside {
  padding: 20px 0;
}
.rightside a {
  text-decoration: none;
  color: black;
}
.contactSection {
  /* margin: 100px 0; */
  margin: 7% 0;
}
.contactsection1 {
  padding: 50px 0;
  max-width: 637px;
}
