/* .Dashboardpage-section{
    margin: 30px;
} */

/* .Dashboardpage-links {
    width: 100%;
    height: 839px;
    box-shadow: 0px 1px 6px #1cb735;
    border-radius: 10px;
    padding: 20px;
} */
.Dashboardpage-links ul {
  list-style-type: none;
  padding: 0;
}

.Dashboardpage-links li {
  /* width: 100%;
    text-decoration: none;
    color: black;
    margin-top: 30px;
    padding: 10px 20px;
    text-align: inherit; */
  font-size: 16px;
  font-family: "Poppins medium";
}

.custom-btn span img {
  width: 32px;
  margin-right: 5px;
}

.tb {
  font-weight: bold;
  font-size: 20px;
  font-family: "Poppins Regular";
}

.ic {
  margin: 0 10px;
  font-size: 14px;
}

.amt {
  font-size: 20px;
}

.detail-box {
  /* padding: 20px; */
  font-family: "poppins";
}

.detail-box h5 {
  font-size: 28px;
  font-family: "Poppins Regular";
}

.box-descript {
  font-size: 16px;
  font-weight: 300;
  font-family: "Poppins Regular";
}

.box-descript li {
  list-style-type: none;
}

.detail-box h1 {
  margin-top: 20px;
  font-family: "Poppins Regular";
}

.breedCum {
  font-family: "Poppins Regular";
  padding-left: 0 !important;
  margin: 0 0 30px 0px;
  display: flex;
  align-items: center;
}

.breedCum span {
  font-size: 20px;
}

.detail-inner-box {
  display: flex;
  justify-content: space-between;
  font-family: "Poppins Regular";
  /* border: 1px solid; */
}

.detail-inner-box p {
  width: 50%;
}

.detail_card_box {
  /* box-shadow: 0px 10px 45px #1cb73621;
    padding: 20px;
    border-radius: 20px; */
  padding: 42px;
  box-shadow: 1px 1px 20px #00000021;
  border-radius: 20px;
}

/* p.box-descript li {
    color: #A8A8A8 !important;
} */

.rx {
  background-color: #f1154a;
}

.btn-x {
  display: flex;
  justify-content: flex-end;
}

.active-link {
  background-color: #1cb735;
  color: #fff !important;
}

.Dashboardpage-links li a span {
  margin-right: 10px;
}

.Dashboardpage-project,
.Dashboardpage-invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* margin: 20px 0; */
}

.Dashboardpage-project-btn button,
.Dashboardpage-invoice-btn button {
  /* width: 140px;
    height: 50px; */
}

.admin-message {
  margin: 20px 0;
}

.project-title .price {
  margin-left: 10px;
}

.project-title {
  padding: 20px;
  box-shadow: 1px 4px 10px grey;
  margin: 20px 0;
  border-radius: 18px;
}

/* .invoice-head {
    color: #fff;
    text-align: center;
    border-radius: 15px 15px 0px 0px !important;
    font-size: 16px;
} */

/* table */

/* thead th {
    background-color: #1CB735;
}

thead th:first-child {
    border-radius: 20px 0 0;
}
tbody tr:last-child {
    border-radius: 0 0 20px 20px;
}

thead th:last-child {
    border-radius: 0 20px 0 0;
}

.invoice-head tr {
    outline: 1px solid #1CB735;
    border-radius: 20px 20px 0 0;
} */

/* table */

/* .invoice-Table table {
    width: 100%;
}

.invoice-head tr th {
    padding: 10px;
}

.invoice-row {
    text-align: center;
    box-shadow: 0px 0px 2px green;
    padding: 10px;
    font-size: 14px;
}

.invoice-row td {
    padding: 10px;
} */

.invoice-row span img {
  margin-left: 5px;
}

.check-icon {
  color: #1cb735;
  font-size: 25px;
}

.close-icon {
  color: red;
  font-size: 20px;
}

.message-container {
  background-color: #4a4a4a;
  color: #fff;
  height: 785px;
  padding: 8px;
  border-radius: 15px;
  position: relative;
}

.message-box {
  color: #fff;
  display: flex;
}

.gray {
  color: #a8a8a8;
}

.message-box-img {
  width: 59px;
  height: 59px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.message-box-img img {
  width: 41.93px;
  height: 20.45px;
}

.xsmall {
  font-size: 14px;
}

.line-img {
  text-align: center;
}

.line-img img {
  width: 100%;
}

.left-box {
  background-color: #707070;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
}

.left-message-box {
  margin: 20px 0;
}

.right-message-box {
  float: right;
}

.right-message-box .gray {
  text-align: right;
}

.messagebox-input {
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
}

.form {
  position: relative;
}

.send-icon {
  position: absolute;
  right: 15px;
  top: 40%;
  font-size: 20px;
}

.messagebox-input .inputbox {
  background-color: #a8a8a8;
  outline: none;
  border: none;
}

.messagebox-input .inputbox::placeholder {
  color: #fff;
}

.question-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-icon {
  font-size: 24px;
  color: #d3d8da;
}

.draft-links ul {
  list-style-type: none;
}

.draft-links ul li a {
  text-decoration: none;
  color: #8f8f8f;
  font-size: 12px;
}

.Budget-admin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 37px;
  position: relative;
}

.Budget-admin-img {
  position: absolute;
  left: 0;
}

.draft-links a {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
}

.active-draft-link {
  border-radius: 20px;
  background-color: #ebf9ea;
  color: #1cb735 !important;
}

.xsmall {
  font-size: 14px;
}

.Budeget-admin h1 {
  font-size: 72px;
}

.projectsection .innerspan {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress {
  width: 80% !important;
  margin-left: 5px;
}

.budgetCard {
  box-shadow: 1px 2px 4px grey;
  border-radius: 8px;
  padding: 10px;
}

.admin-messsage-box {
  height: 337px;
  background-color: #4a4a4a;
  color: #fff;
  padding: 10px;
  border-radius: 12px;
  overflow-y: scroll;
}

.admin-messsage-box-card {
  display: flex;
  width: 100%;
}

.admin-messsage-innerbox {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
}

.admin-messsage-innerbox1 {
  display: flex;
  justify-content: space-between;
}

.admin-messsage-box-card {
  background-color: #818181;
  border-radius: 12px;
  padding: 10px;
  margin-top: 10px;
}

.chart-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

.chart-heading span {
  font-size: 18px;
}

.chart-heading span .down-icon {
  font-size: 30px;
  padding: 0;
}

.chart-card {
  box-shadow: 0px 1px 4px grey;
  border-radius: 12px;
  padding: 14px;
}

/* Message sidebar css starts */

:is(.outgoing1, .incoming1) .left-box {
  position: relative;
  margin-block-end: 10px;
  clear: both;
  margin-bottom: 12px;
}

:is(.outgoing1, .incoming1) .left-box::before {
  content: "";
  height: 10px;
  width: 20px;
  position: absolute;
  bottom: -10px;
  background: inherit;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: rotate(180deg);
}

.incoming1 .left-box::before {
  left: 30px;
}

.outgoing1 .left-box::before {
  right: 30px;
}

.msg_timing {
  color: #fff;
}

.outgoing1 .msg_timing {
  float: right;
}

.incoming1 {
  width: 70%;
}

.outgoing1 {
  width: 70%;
}

.messageSidebar_scroll {
  overflow-y: scroll;
  height: 622px;
  padding: 0 4px;
}

.messageSidebar_scroll::-webkit-scrollbar {
  height: 8px;
  width: 5px;
  background: rgb(153, 148, 148);
}
.change_password_modal .password-eye{
  right: 80px;
}


/* Message sidebar css ends */
@media (max-width: 992px) {
  .editProfile_form input,
  .editProfile_form textarea {
    width: 100% !important;
  }

  .change_password_modal .password-eye{
    right: 40px;
  }
  .change_password_modal input,
  .change_password_modal textarea {
    width: 100% !important;
  }

 


}
