.brandsLogo-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 109px;
    text-align: center;
    flex-wrap: wrap;
    /* background-color: #ebf8eb; */
}
.brandsLogo-section .container{
    /* background-color: #ebf8eb; */
}
.logos img {
    width: 127px;
    height: 44px;
}