.Order {
  position: relative;
}
.row.line {
  position: relative;
}
.row.line:after {
  content: "";
  position: absolute;
  width: 3px;
  bottom: -6%;
  left: 50%;
  top: 0;
  height: 100%;
  z-index: 9 !important;
  background-image: url("../images/Line\ 2.png");
}

.Ordersection-line {
  position: absolute;
  width: 1%;
  bottom: -6%;
  left: 49.43%;
}

.orderSection {
  text-align: center;
  margin: 100px 0;
}

.OrderImg img {
  width: 100%;
}
.order-img-box{
    width: 100%;
    height: 400px;
}
.order-img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.OrderImg {
  text-align: center;
  width: 400px;
  transform: translate(10px, -90px) !important;
}

.circleImg {
  text-align: center;
  /* margin-top: 100px; */
}

.OrderContent .mid {
  margin-bottom: 150px;
}
