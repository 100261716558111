/* .Dashboardpage-section {
} */

:root {
    --brown_color: #4a4a4a;
    --pink_color: #8f8f8f;
}

.projectCard-box .discript_heading {
    color: var(--brown_color);
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins Regular';
}

.cardPara {
    font-size: 15px !important;
}

.price {
    font-size: 14px;
}


/* 
.projectCard-box {
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding-inline: 10px;
    padding-bottom: 10px;
} */


/* 
.projectCard-box::-webkit-scrollbar {
    height: 8px;
    width: 5px;
    background: whitesmoke; */


/* .projectCard-box::-webkit-scrollbar-thumb:horizontal {
    background: #DCF9D7;
    opacity: 0.7;
    border-radius: 10px;
    width: 4px;
} */

.projectCard-box .project-card {
    padding: 20px;
    box-shadow: 0px 1px 6px #1cb735;
    border-radius: 18px;
    width: 100%;
    height: 400px;
    margin: 10px 10px;
    /* flex-basis: 42%;
    flex-shrink: 0; */
}

.MyprojectCard-box .project-card {
    width: 100% !important;
    padding: 35px !important;
    box-shadow: 0px 1px 6px #1cb735;
    border-radius: 18px;
    height: 100%;
}

.assignedProjectCard_row {
    row-gap: 20px;
}

.myproject-row {
    row-gap: 1.5rem;
}


/* .MyprojectCard-box .project-card h5{
    font-size: 20px;
    height: 36px;
} */

.project-card h3 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.MyprojectCard-box h5 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* height: 17%; */
    height: 50px;
}

.MyprojectCard-box .discript_heading {
    color: black;
    font-size: 20px;
    font-weight: 500;
}

.project-card .description {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #8f8f8f;
    font-size: 16px;
}

.project-card .descriptionShort {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #8f8f8f;
    /* height: 30px; */
}


/* p.discript_heading {
    font-size: 19px;
    font-weight: bold;
} */

.projectCard-box .project-card h5 {
    min-height: 17%;
    text-transform: capitalize;
}