.navbar-btn1{
  /* width: 170px;
  height: 50px; */
  /* padding-left:  0 ;
  padding-right: 15px ; */
  padding: 5px 20px 5px 5px;
}
.navbarbtn-icon{
   font-size: 30px ;
   margin-right: 5px ;
   padding: 0 ;
   
}
/* .navbar-light .navbar-nav .nav-link {
  padding: 16px;
  color: #A8A8A8;
} */