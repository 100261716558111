.cameraicon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  border-radius: 50%;
  overflow: hidden;
}

.cameraicon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  border-radius: 50%;
}

.UploadProfile input {
  background-color: #0b30a9;
  border-radius: 50px;
  padding: 8px 50px;
  color: white;
  border: none;
}

.chooseFile-box {
  background-color: #0b30a9;
  border-radius: 50px;
  padding: 8px 50px;
  color: white;
  border: none;
}

.UploadProfile p {
  text-align: left;
  font: normal normal normal 14px/24px Poppins;
  letter-spacing: -0.42px;
  color: #888888;
  opacity: 1;
}

.UploadProfile {
  display: flex;
  /* flex-direction: column; uncomment this */
  justify-content: center;
  height: 100%;
  width: 100%; /* comment this */
  margin-top: 14px; /* comment this */
  margin-left: 0 !important;
}

.setting_fileupload {
  padding: 10px 20px;
  width: 25%;
  border-radius: 30px;
  color: white;
  border: 1px solid #d4d4d4;
  background-color: #1CB735;
}

.setting-innerDiv_fileUpload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file_upload_form3rd[type="file"] {
  display: none;
}

/* New */
.img-main-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
