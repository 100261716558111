.container.custom-container {
  max-width: 1600px;
}

.container.custom-container.py {
  padding-block: 2%;
}
/* .signup_screen{
  width: 100% !important;
} */

.body-loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: rgba(28, 183, 53, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

/* p-d-null-data */

.p-d-null-data {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active-icon img {
  filter: brightness(100);
}

.c-p {
  cursor: pointer;
}

.detail_card {
  width: 70%;
  padding: 32px;
  box-shadow: 1px 1px 5px grey;
  border-radius: 7px;
}

.btn-detail {
  padding: 15px 20px;
  margin: 20px 5px;
}

.image-multi-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 3% 0%;
}

.img_compo_head {
  width: 100%;
}
.img_compo_head .upload-image-title {
  margin-bottom: 0px;
  margin-top: 2px !important;
}
.img_compo_head > small {
  margin-top: 20px;
  text-align: left;
  display: flex;
  margin-bottom: 15px;
}
.img_compo_head > div {
  border: 1px solid #1cb735;
  height: 57px;
  overflow: hidden;
  border-radius: 0.25rem;
  width: 100%;
  margin-top: 5px;
}

.custom-image-upload {
  border: 1px solid #1cb735;
  border-radius: 20px;
  height: 132px;
  margin-right: 0.8%;
  overflow: hidden;
  width: 29.5%;
}
.support_mainContainer img {
  max-width: 100%;
}

.file-pdf-select {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
}

.c-p {
  cursor: pointer;
}

.avtar-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avtar-box span {
  font-size: 16px;
  color: #707070;
  margin-left: 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.headerAvtar {
  margin-left: 20px;
  cursor: pointer;
}

.dropdown_box > .nav-link {
  text-decoration: none;
  color: gray !important;
  width: inherit;
}

.userMenu_B ul.MuiList-root.MuiList-padding.MuiMenu-list {
  padding-top: 20px;
  padding-bottom: 20px;
}

.userMenu_B
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  border-radius: 20px 0px 20px 20px;
  width: 235px;
  border: 1px solid #1cb735;
  top: 90px !important;
}

.Dashboardpage-project-btn button,
.Dashboardpage-invoice-btn button {
  /* width: 140px;
    height: 50px; */
  padding: 15px 30px;
}

.mt-25-vp {
  margin-top: 5vh;
}

.t-t-c {
  text-transform: capitalize !important;
}

.nullData_image {
  width: 30%;
}

.nullData-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.h-50-imp {
  height: 25% !important;
}

body {
  overflow: visible !important;
  padding: 0 !important;
}
.linkgreen {
  color: #1cb735 !important;
  /* font-weight: bold; */
}

.active-link_nav {
  color: black !important;
  font-weight: 600;
  /* text-decoration: underline; */
}

.w-60 {
  width: 50vw;
}

.disableBtn-xxx {
  background-color: rgba(28, 183, 53, 0.55) !important;
}

.df-jc-ac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-style-x {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 185px;
  padding: 5px 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 5px grey;
  text-transform: capitalize;
  outline: none;
  border: none;
}

.dropdown-style-x option {
  padding: 10px;
  text-transform: capitalize;
}

.op-0 {
  opacity: 0;
}

.latestNewsTitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 25%;
}

.assignedProject-Container > .MyprojectCard-box {
  height: auto !important;
}
button#rcc-confirm-button {
  background: green !important;
  color: white !important;
  padding: 10px 20px !important;
}
button#rcc-decline-button {
  background: #be2b2b !important;
  color: white !important;
  padding: 10px 20px !important;
}

.view-more-img-div {
  height: "400px";
  /* object-fit: contain; */
  margin-bottom: 20px !important ;
}
.view-more-img-div img {
  width: 100%;
  height: 279px !important;
  object-fit: contain !important;
}

.relax-man-col1 {
  display: flex;
  align-items: center;
}
input[type="checkbox"] {
  accent-color: #508d5a !important;
  color: #fff !important;
}

span.sgin-line {
  height: 32px;
  border-left: 2px solid gray;
  width: 15px;
  margin-top: 10px;
}
.aboutsection-video {
  width: 100%;
  height: 550px !important;
  border-radius: 24px !important;
  position: relative;
}
.aboutsection-video .react-player iframe {
  border: 0;
  border-radius: 25px;
}
.aboutsection-video .react-player video {
  border-radius: 30px !important;
  border: 2px solid !important;
  object-fit: cover;
}
.video-overLay {
  position: absolute;
  display: block;
  content: " ";
  background-color: #000000a3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.player-icon {
  font-size: 100px;
  color: #fff;
  /* z-index: 99999; */
}
.HeroSection {
  position: relative;
}
.ScrollIcon-div {
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translate(0px, -60px);
}
.ScrollIcon {
  font-size: 47px;
  color: grey !important;
}
.custom-image-upload img {
  object-fit: contain;
}
.selectimgs img {
  object-fit: contain;
}

/* .ScrollIcon-div a{
    text-align: center;
} */

/* .z-999999999999999 {
    z-index: 9999999999999999999999999999999999999999999;
} */

.f19px {
  font-size: 19px !important;
  color: #1cb735;
}

@media (max-width: 1200px) {
  .container {
    max-width: 1058px;
  }
}

.phone-container {
  width: 608px;
  height: 60px;
  margin: 0 auto;
  border: 1px solid #1cb735;
  margin-top: 16px;
  width: 92% !important;
}

.phone-container input {
  height: 58px !important;
  width: 100% !important;
  border-radius: 0 !important;
}

.p-i28 {
  padding-inline: 28px;
}

.signup_screen {
  height: 90px !important;
  width: 608px !important;
}
.bank-detail-area {
  width: 608px;

  margin: auto;
  border: 1px solid #1cb735;
}

.p-r {
  position: relative;
}

.password-eye {
  position: absolute;
  color: #1cb735;
  font-size: 35px;
  cursor: pointer;
  right: 40px;
  top: 5px;
}

.r-10 {
  right: 10% !important;
}
.w84 {
  width: 84% !important;
}

.phoneContainerM {
  height: 60px;
  margin: 0 auto;
  border: 1px solid #1cb735;
  margin-top: 16px;
  width: 84% !important;
}

.phoneContainerM input {
  height: 58px !important;
  width: 100% !important;
  border-radius: 0 !important;
}

.markasReadBtmn {
  text-decoration: underline;
  color: rgb(28, 183, 53);
  cursor: pointer;
}

.exitBtn {
  margin-bottom: 20px;
  padding: 0;
}

.Notification-text span {
  font-size: 13px;
  color: #9d8f8f;
  position: absolute;
  right: 10%;
}

.delete-account-btn {
  display: block !important;
  margin: 10px 60px !important;
  background: red !important;
}

.passwordeye {
  position: absolute;
  color: #1cb735;
  font-size: 35px;
  cursor: pointer;
  right: 74px;
  top: 5px;
}

.pending,
.Pending {
  color: #ffbf00 !important;
  text-transform: capitalize !important;
}

.underReview {
  color: purple !important;
  text-transform: capitalize !important;
}

.inProgress {
  color: red !important;
  text-transform: capitalize !important;
}

.completed,
.paid,
.Paid,
.Active,
.active,
.accepted {
  color: #1cb735 !important;
  text-transform: capitalize !important;
}

.Currencyinput > div {
  min-height: 47px !important;
  /* opacity: 0.5; */
}

.maxLine1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.maxLine2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.secureInputLock {
  position: absolute;
  top: 10px;
  left: 50%;

  color: #fff;
  font-size: 22px;
  transform: translate(-50%);
}

.secure-input,
.secure-input:focus {
  border: 2px solid #1cb735 !important;
  border-top: 40px solid #1cb735 !important;
  border-color: #1cb735 !important;
}

.payment-info {
  margin: 10px 60px;
  cursor: pointer;
}

.payment-info svg {
  font-size: 22px;
}

.payment-info-container {
  position: absolute;
  margin: 0 !important;
  top: 8px;
  right: 42px;
}

.payment-info-container svg {
  color: white !important;
}

.loc-activated {
  color: #1cb735 !important;
}

.modal-Header-reject {
  height: 90px !important;
}

.modal-Header-reject p {
  margin-bottom: 0 !important;
  font-weight: 500;
  font-size: 24px !important;
}
