:root {
    --pink-color: #707070;
}

/* .add-project-section {
    margin: 5% 0px;
} */

.add-project-section h2 {
    /* text-align: center; */
    margin-bottom: 2%;
}

.field-margin {
    margin-bottom: 2%;
}

.add-project-section .input {
    padding: 10px;
    border: 1px solid var(--pink-color);
}

.add-project-section .btn-box {
    margin-top: 3%;
}

.add-project-section .btn-box button {
    padding: 13px 20px;
    background-color: #1cb735;
}

.add-project-section textarea {
    border: 1px solid var(--pink-color);
}

.add-project-section .addproject_form {
    box-shadow: 0px 0px 4px #1cb735;
    padding: 40px 30px;
    border-radius: 15px;
}


.css-1s2u09g-control{
    border: none !important;
    box-shadow: none !important;
    border: 1px solid var(--pink-color) !important;

}
    .css-6j8wv5-Input {
        padding: 8px !important;
        color: #212529 !important;
        font-size: 1rem !important;
        font-weight: 600 !important;
    }
    .css-1s2u09g-control:focus {
      border: 1px solid #1cb735 !important;
      outline: #1cb735 !important;
    }

    .css-1s2u09g-control :hover{
       border-color: none !important;
    }

    .form-control:focus {
        color: #212529;
        background-color: #fff !important;
        border-color: #1cb735 !important;
        /* border: 2px solid #1cb735 !important; */
        /* outline: 0 !important; */
        box-shadow: none !important;
    }