.Footer-section{
     background-color: #292929;
    padding-top: 50px;
    padding-bottom: 20px;
}
.footer-leftside .mid{
    color: #A8A8A8;
   margin-top: 20px;
    margin-bottom: 30px;
    
}
.footer-leftside{
    max-width: 76%;
}
.phone_num{
    display: flex;
    align-items: center;
}
.social-icons{
    color: #1CB735;
    font-size: 30px;
    margin: 10px;   
}
.footer-rightside a{
    text-decoration: none !important;
    color: #A8A8A8 !important;
}
.social-icons:hover{
    cursor: pointer;
}
.footer-middile , .footer-rightside{
    margin-top: 50px;
}
.footer-middile h2 , .footer-rightside h2{
    color: #ffff;
}
.footer-middile ul li span{
    text-decoration: none;
    color: #A8A8A8;
    cursor: pointer;
}
.footer-middile ul  {
    list-style-type: none;
    padding: 5px;
    
}
.footer-middile ul li{
   padding: 10px;
}
.footer-rightside span {
    color: #A8A8A8;
   font-size: 18px;
}
.footer-rightside span .num{
    border: 1px solid wheat;
}
.copyright-text{
    text-align: center;
}
.copyright-text .mid{
    color: #A8A8A8;
    margin-top: 50px;
}
.con{
    display: flex;
    color: #A8A8A8;
    font-size: 18px;
}
.social-icons-new{
    width: 30px;
    height: 30px;
}
.social-icons-new img{
    width: 100%;
    height: 100%; 
}
.icons-div {
    display: flex;
    column-gap: 24px;
}