.SignUppage-section {
    background-image: url("../images/newimage.svg");
    background-size: contain;
    background-position: bottom center;
    background-repeat: no-repeat;
}
.SignUppage-section1 .image-multi-container {
    width: 92%;
    margin: auto !important;
}

.SignUppage-section1 {
    width: 704px;
    /* height: 916px; */
    text-align: center;
    margin: 42px auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 50px 20px;
    box-shadow: 2px 2px 7px #707070;
}

.siginInputbox {
    width: 608px;
    height: 60px;
    margin: 0 auto;
    border: 1px solid #1cb735;
}

.SignUppage-btn button {
    width: 608px;
    height: 60px;
}

.SignUppage-section1 .big {
    padding: 13px 0;
}

.big span {
    color: #1cb735;
}

.SignUp-check {
    display: flex;
    align-items: center;
    width: 608px;
    margin: 0 auto;
    padding: 10px 0;
}

.form-check {
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.form-check-label {
    padding-left: 10px;
    padding-top: 3px;
    color: black !important;
}

.SignUp-check .big span {
    color: #1cb735;
}

.green {
    color: #1cb735;
    font-size: 20px;
}

.SignUppage-section .role-btns {
    margin: 4% 0%;
}

.SignUppage-section .role-btns button:first-child {
    margin-right: 3%;
}

.terms_conditions {
    cursor: pointer;
}

.SignUppage-section .role-btn-selected {
    background-color: #1cb735;
    border: 8px;
    padding: 10px 20px;
    border: 2px solid #1cb735;
}

.SignUppage-section .role-btn {
    background-color: white;
    border: 8px;
    padding: 10px 20px;
    border: 2px solid #1cb735;
    color: #1cb735;
}