.onboardprocess {
    margin: 5% 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
}

.onboardprocess-section .line-img {
    position: absolute;
    text-align: center;
    width: 750px;
    left: 0px;
    right: 0;
    top: 40%;
    margin-inline: auto;
    z-index: -1;
}

.onboardprocess-section .line-img img {
    width: 100%;
}


/* .onboardprocess .row::before{
    content: "";
    background-image: url('../images/Path 498.png');
    position: absolute;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    top: 39%;
    left: 24%;
    z-index: -1;
} */

.onboardprocess-section {
    text-align: center;
    max-width: 800px;
    text-align: center;
    margin: auto;
}

.onboard-card {
    /* width: 396px;
    height: 388.68px; */
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.onboard-card .mid {
    max-width: 284px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.onboard-card-img-box {
    width: 194.75px;
    height: 173.63px;
}

.onboard-card-img-box img {
    width: 100%;
}

.onboardbtn button {
    padding: 10px 20px;
}


/* @media (max-width: 1440px) {
  .onboardprocess-section .line-img {
    width: 634px;
    top: 40%;
  }
}
@media (max-width: 1200px) {
  .onboardprocess-section .line-img {
    width: 630px;
    top: 34%;
  }
} */