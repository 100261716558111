.SignInpage-section {
  background-image: url("../images/newimage.svg");
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.SignInpage-section1 {
  width: 704px;
  /* height: 656px; */
  text-align: center;
  margin: 100px auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 50px 20px;
  box-shadow: 2px 2px 7px #707070;
}
/* .form-control */
.siginInputbox {
  width: 608px;
  height: 60px;
  margin: 0 auto;
  border: 1px solid #1cb735;
}
.SignInpage-btn button {
  width: 608px;
  height: 60px;
}
.SignInpage-section1 .big {
  padding: 13px 0;
}
.big span {
  color: #1cb735;
}
.check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 608px;
  margin: 0 auto;
  padding: 10px 0;
}
.form-check {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.form-check-label {
  padding-left: 10px;
  padding-top: 10px;
  color: black !important;
}
.check .big span {
  color: #1cb735;
}
.green {
  color: #1cb735;
  font-size: 20px;
}
