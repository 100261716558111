.HeroSection{
    /* background-image: url('../images/Group 548.png'); */
    background-size: contain;
    background-position: bottom ; 
    background-repeat: no-repeat;
    width: 100%;
}
.higheststandard{
 /* margin-top: 170px; */
 width: 100%;
 
}
.higheststandard-btn1{
    padding: 10px 20px;
    margin-top: 10px;
}
.higheststandard-section2{
    position: relative;    
}
.higheststandard-section2 img{
    width: 100%;
}

.higheststandard-col  {
        display: flex;
        align-items: center;
    }
.higheststandard-col2  {
        display: flex;
        align-items: center;
    }
    .higheststandard-section2 {
        width: 533px;
        height: 468px;
        padding: 50px 0;
    }
    .higheststandard-section2 img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .logos {
        width: 127px;
        height: 44px;
    }
    .logos img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .navbar-brand img {
        margin-left: 0px !important;
    }
    
