@media (max-width: 1920px) {
  .higheststandard-section2 img {
    height: 100% !important;
  }
}

@media (max-width: 1600px) {
  .cardPara {
    font-size: 12px !important;
  }
  .price {
    font-size: 13px;
  }
  /* .higheststandard-section2 img {
        height: 65% !important;
    } */
  /* .higheststandard-col {
        height: 560px !important;
    }
    .ScrollIcon-div {
        transform: translate(0px,-275px) !important;
    } */
  .ScrollIcon-div {
    transform: translate(0px, -24px) !important;
  }
  h1 {
    font-size: 40px !important;
  }
  .Servicespage-section3-heading {
    max-width: 800px !important;
  }
  .Servicespage-section4-heading {
    max-width: 650px !important;
  }
  .sec-2 {
    width: 75% !important;
    height: 226px !important;
    margin: 25px auto !important;
  }
  .container.custom-container {
    max-width: 1360px !important;
  }
  .HeroSection {
    height: calc((100vh - 109px) - 103px);
  }
}

@media (max-width: 1440px) {
  .phone-container,
  .image-multi-container {
    width: 97% !important;
  }
  .signup_screen_phone {
    width: 97% !important;
  }
  .Servicespage-section1 {
    height: 370px;
  }
  .HeroSection {
    /* height: calc((100vh - 109px) - 264px); */
  }
  .Servicespage-section1-heading {
    height: 300px;
  }
  .cardPara {
    font-size: 12px !important;
  }
  .onboardprocess-section .line-img {
    width: 800px;
    top: 39%;
  }
  .Services .Services-card {
    height: 520px;
  }
  .Order .Ordersection-line {
    left: 49.3%;
  }
  .NewsCard .card {
    height: 560px !important;
  }
  /* Dashboards css starts */
  .project-card .description {
    /* height: 88px !important; */
  }
  /* Dashboards css ends */
  .my-project-container {
    width: 999px !important;
    margin: auto !important;
  }
  .MyprojectCard-box {
    height: 380px !important;
  }
  .MyprojectCard-box .project-card {
    padding: 20px 19px !important;
  }
  .green {
    font-size: 14px !important;
  }
  .MyprojectCard-box > .project-card > .btn-detail {
    padding: 9px 10px !important;
  }
  .SignInpage-section1 {
    width: 662px !important;
    margin: 18px auto 43px auto !important;
    padding: 24px 20px !important;
  }
  .SignUppage-section1 {
    width: 667px !important;
    margin: 14px auto 29px auto !important;
    padding: 25px 20px !important;
  }
  span.sgin-line {
    height: 27px !important;
    border-left: 2px solid gray;
    width: 11px;
    margin-top: 8px !important;
  }
  .higheststandard-section2 img {
    height: 80% !important;
  }
  .form-check-label {
    font-size: 16px;
  }
  .cardPara {
    font-size: 12px !important;
  }
}

@media (max-width: 1400px) {
  .higheststandard-section2 img {
    height: 100% !important;
  }
  .higheststandard-section2 {
    padding: 20px 0;
  }
}

@media (max-width: 1366px) {
  h2 {
    font-size: 28px !important;
  }
  .Services .Services-card {
    height: 450px;
  }
  .Order .Ordersection-line {
    left: 49.2%;
  }
  .NewsCard .card {
    /* height: 510px !important; */
  }
  .Dashboardpage-links li {
    font-size: 16px !important;
    margin-top: 10px !important;
  }
  .Dashboardpage-section .Dashboardpage-links {
    height: 74vh !important;
  }
  .custom-btn span img {
    width: 22px !important;
  }
  .projectCard-box .project-card {
    padding: 34px !important;
    height: 351px !important;
  }
  span.green.price {
    font-size: 14px;
  }
  .invoice-Table .btn-detail {
    padding: 10px 16px !important;
  }
  .invoice-row td {
    padding: 5px !important;
    font-size: 15px !important;
  }
  .invoice-head tr th {
    padding: 8px !important;
    font-size: 15px !important;
  }
  .invoice-row img {
    width: 15px;
  }
  .Dashboardpage-project-btn button,
  .Dashboardpage-invoice-btn button {
    padding: 12px 20px !important;
  }
  .project-card .description {
    -webkit-line-clamp: 3 !important;
    /* height: 66px !important; */
  }
  .MyprojectCard-box .discript_heading {
    font-size: 15px !important;
  }
  .nullData_image {
    width: 22% !important;
  }
  .navbar-brand img {
    width: 140px !important;
    margin-left: 30px !important;
  }
  .breedCum {
    margin: 30px 0 19px 0px !important;
  }
  /* .detail-box {
        padding: 0px !important;
    } */
  h5 {
    font-size: 18px !important;
  }
  .tb {
    font-size: 19px !important;
  }
  .amt {
    font-size: 16px !important;
  }
  .box-descript {
    font-size: 17px !important;
  }
  .meet-img-inner-box h5 {
    font-size: 10px !important;
  }
  .projectCard-box .project-card h5 {
    height: 45px !important;
  }
  .mid.project-card.c-p h5 {
    font-size: 20px !important;
  }
  .MyprojectCard-box .project-card {
    padding: 18px !important;
  }
  /* Dashboard css starts */
  .Dashboardpage-links li {
    padding: 10px 4px !important;
  }
  /* Dashboard css ends */
  .MyprojectCard-box {
    height: 400px !important;
  }
  .cardPara {
    font-size: 12px !important;
  }
  .detail_card_box {
    width: 98%;
  }
}

@media (max-width: 1280px) {
  .card-cover-img {
    width: 100% !important;
    height: 240px !important;
  }
  .latestNews-img-box {
    width: 100% !important;
    height: 250px !important;
  }
  .my-project-container {
    width: 960px !important;
  }
}

@media (max-width: 1024px) {
  .Servicespage-section1 {
    height: 280px;
  }
  h1 {
    font-size: 32px !important;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 8px !important;
  }
  .HeroSection {
    padding: 30px 0;
  }
  .Order .OrderImg img {
    width: 90% !important;
  }
  .NewsCard .card {
    height: 470px !important;
  }
  .Services .Services-card {
    height: 409px;
  }
  .onboard-card-img-box img {
    width: 75%;
  }
  .onboardprocess-section .line-img {
    width: 700px;
    top: 37%;
  }
  .card-img .img2 {
    width: 100px;
    height: 100px;
  }
  .Order .Ordersection-line {
    left: 49%;
  }
  .Dashboardpage-links li {
    font-size: 14px !important;
  }
  /*############# Our aboutus page starts ################ */
  /* .aboutSection-work {
        margin: 0 !important;
    } */
  .aboutSection1-box {
    margin-bottom: 200px !important;
  }
  /*############# Our aboutus page ends ################ */
  /*############# My Projects page starts ################ */
  .MyprojectCard-box .project-card {
    width: 100% !important;
  }
  .df-jc-ac {
    width: 96%;
  }
  .Contact-box {
    width: 443px !important;
  }
  .Contact-box {
    padding: 21px !important;
  }
  /*############# My Projects page ends ################ */
  .MyprojectCard-container > .MyprojectCard-box > .project-card {
    width: 100% !important;
  }
  .MyprojectCard-container.col-md-4 {
    width: 50% !important;
  }
  .Dashboardpage-section {
    margin: 30px 0px !important;
  }
  .card-cover-img {
    width: 100% !important;
    height: 198px !important;
  }
  .latestNews-img-box {
    width: 100% !important;
    height: 220px !important;
  }
  span.sgin-line {
    height: 23px !important;
    width: 15px !important;
    margin-top: 9px !important;
  }
  .form-check-label {
    font-size: 14px !important;
  }
  .higheststandard-section2 img {
    height: 90% !important;
  }
}

@media (max-width: 992px) {
  a {
    font-size: 15px;
  }
  .Dashboardpage-section .Dashboardpage-links {
    padding: 4px !important;
  }
  .df-jc-ac {
    width: 95%;
  }
  .Dashboardpage-section {
    margin: 30px 0px !important;
  }
  .mid.project-card.c-p h5 {
    font-size: 17px !important;
  }
  .Dashboardpage-section {
    margin: 0;
  }
  .chatMessage_sect .logo_main {
    padding: 12px 10px !important;
  }
  .view-more-img-div img {
    width: 100%;
    height: 254px !important;
    object-fit: contain !important;
    margin: 25px 0 !important;
  }
  span.sgin-line {
    height: 26px !important;
    margin-top: 8px !important;
  }
  .Contactus-section {
    max-width: 582px !important;
  }
  .Footer-container {
    max-width: 900px;
  }
  .navbar-collapse {
    text-align: center;
  }
  .detail_card_box {
    width: 96%;
    margin: 0 20px;
  }
  .breedCum {
    /* margin-left: 20px !important; */
    padding-left: 20px;
  }
}

@media (max-width: 991px) {
  .social-icons {
    font-size: 18px;
    margin: 5px;
  }
  .onboardprocess-section .line-img {
    display: none;
  }
  .HeroSection {
    height: calc((100vh - 109px) - 87px);
  }
  h2 {
    font-size: 20px !important;
  }
  .Order .row.line:after {
    display: none;
  }
  .Order .circleImg {
    display: none;
  }
  .HeroSection {
    height: auto;
  }
  .higheststandard-section2 {
    display: none;
  }
  .brandsLogo-section {
    height: auto;
  }
  /* ######Home page starts######## */
  .higheststandard-section2 img {
    display: none;
  }
  .higheststandard {
    /* margin-top: 50px !important; */
    text-align: center;
  }
  .brandsLogo-section .logos {
    margin: 50px auto;
  }
  .line-img {
    display: none;
  }
  .onboard-card-row {
    display: flex;
    justify-content: center;
  }
  .latestNew-row {
    row-gap: 30px;
  }
}

@media (max-width: 800px) {
  .footer-middile,
  .footer-rightside {
    /* margin-left: -25px; */
  }
  .footer-rightside a {
    font-size: 12px;
  }
  .detail_card_box {
    width: 97%;
    margin: 0 11px;
  }
}

@media (max-width: 768px) {
  .icons-div {
    display: flex;
    justify-content: center;
  }
  .SignInpage-section1 {
    width: 90% !important;
  }
  .HeroSection {
    /* height: calc((54vh - 109px) - 87px); */
    height: auto;
  }
  .msg_heading {
    margin: 50px auto !important;
  }
  sub,
  sup {
    line-height: 2.5;
    padding-left: 5px;
  }
  /* ######Home page starts######## */
  .higheststandard-section2 img {
    display: none;
  }
  .higheststandard {
    /* margin-top: 50px !important; */
  }
  .brandsLogo-section .logos {
    margin: 50px auto;
  }
  .line-img {
    display: none;
  }
  .Order .Ordersection-line > img {
    display: none;
  }
  .Services .Services-card {
    height: 460px;
  }
  .order-img-box.OrderImg {
    margin: 0 auto;
    transform: unset !important;
  }
  .circleImg {
    display: none;
  }
  .onboardprocess-section {
    /* margin-top: 160px !important; */
  }
  .row.line > div:nth-child(1) {
    order: -1;
  }
  .row.line > div:nth-child(3) {
    order: -1;
  }
  .row.line > div:nth-child(6) {
    order: 3;
  }
  .row.line > div:nth-child(4) {
    order: 4;
  }
  section.Order .row.line > div:nth-child(8) {
    order: 7;
  }
  section.Order .row.line > div:nth-child(9) {
    order: 9;
  }
  .row.line > div:nth-child(4) {
    order: 3;
  }
  /* section.Order .row.line>div:nth-child(3) {
        order: 2;
    }
    section.Order .row.line>div:nth-child(5) {
        order: 2;
    }
    section.Order .row.line>div:nth-child(6) {
        order: 8;
    }
    section.Order .row.line>div:nth-child(8) {
        order: 7;
    }
    section.Order .row.line>div:nth-child(9) {
        order: 9;
    }
    section.Order .row.line>div:nth-child(11) {
        order: 9;
    }
    section.Order .row.line>div:nth-child(12) {
        order: 11;
    }
    section.Order .row.line>div:nth-child(14) {
        order: 10;
    } */
  .orderSection {
    margin: 40px !important;
  }
  .OrderContent {
    margin: 25px 0 !important;
    text-align: center;
  }
  .testimonial {
    margin: 30px 0 0 0 !important;
  }
  .Order {
    margin-top: -60px;
  }
  .OrderContent .mid {
    margin-bottom: 30px !important;
  }
  .NewsCard .card {
    height: 700px !important;
    margin: 20px 0;
  }
  .higheststandard {
    text-align: center;
    margin-bottom: 30px;
  }
  /* ######Home page ends######## */
  .navbar-collapse {
    text-align: center;
  }
  .collapseIcon > span {
    color: #fff;
    font-size: 25px;
    padding: 0px 1px !important;
  }
  .cNNJIt {
    width: 30px;
    height: 40px;
    min-width: 40px;
    margin-left: 20px;
  }
  .jRFJkP {
    width: 30px;
    height: 40px;
    min-width: 40px;
    margin-right: 10pz;
  }
  .Dashboardpage-project,
  .Dashboardpage-invoice {
    justify-content: space-between !important;
  }
  .collapseIcon.c-p {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: block;
    transform: rotate(180deg);
    z-index: 99999;
  }
  .collapseIcon svg {
    margin: -11px 0 0 2px;
  }
  .Dashboardpage-section-open-x {
    width: 70px !important;
  }
  .Dashboardpage-section .Dashboardpage-links {
    padding: 18px !important;
  }
  .rec.rec-arrow-left,
  .rec.rec-arrow-right {
    display: none;
  }
  th {
    white-space: nowrap;
  }
  /*############# Our Services page starts ################ */
  h1 {
    font-size: 26px !important;
  }
  h2 {
    font-size: 22px !important;
  }
  .Servicespage-section2 {
    margin: unset !important;
  }
  .Servicespage-section2-leftside,
  .Servicespage-section2-rightside {
    margin: 20px 0 !important;
  }
  .Servicespage-section3 {
    padding: 50px 0 !important;
  }
  .Servicespage-section3-box {
    margin: 0 auto !important;
    padding: 10px !important;
  }
  .footer-leftside {
    max-width: 100% !important;
    /* text-align: center !important; */
  }
  .footer-middile {
    margin-left: 50px;
  }
  .footer-rightside {
    /* text-align: left !important; */
  }
  .con .phone_num {
    display: flex;
    flex-wrap: wrap;
    /* width: 38%; */
    /* margin: 20px auto; */
  }
  .con .phone_num span {
    height: 100%;
    width: fit-content;
  }
  .Servicespage-section2-leftside {
    text-align: center;
  }
  /*############# Our Services page ends ################ */
  .aboutSection-img {
    position: unset !important;
  }
  .aboutSection1-box {
    position: unset !important;
  }
  .aboutSection-text {
    margin: 0 auto;
  }
  .aboutSection-img {
    text-align: center;
    margin: 14px auto;
    width: 80% !important;
  }
  .aboutSection-text {
    width: 80% !important;
    padding: 5% !important;
  }
  .aboutSection1-box {
    margin-bottom: 100px !important;
  }
  .invoice-Table {
    overflow-x: scroll;
    padding: 10px;
  }
  /* #####Contact us starts###### */
  .contactsection1 {
    text-align: center !important;
    padding: unset !important;
    max-width: unset !important;
  }
  /* .con {
        display: flex;
        text-align: center;
        justify-content: center;
    } */
  /* #####Contact us ends###### */
  .HowToOrderpage-section3-leftside {
    text-align: center;
  }
  .latestNews-768 {
    width: 50%;
  }
  .NewsCard .card {
    height: 500px !important;
  }
  .latestNewsTitle {
    height: 22% !important;
  }
  .contactsection1 {
    /* text-align: left !important; */
  }
  .rightside {
    width: 34% !important;
    margin: auto !important;
    text-align: left !important;
  }
  .con {
    text-align: left !important;
  }
  .MyprojectCard-container > .MyprojectCard-box > .project-card {
    width: 100% !important;
  }
  .df-jc-ac {
    width: 100%;
  }
  .selectImg_box {
    margin: auto !important;
  }
  /* .detailbtn-container {
        display: flex;
        justify-content: center;
    } */
  .modal-lg,
  .modal-xl {
    max-width: 600px !important;
  }
  .custm_masterCard .custmImg {
    width: 175px !important;
  }
  .custmImg1.col-lg-4 {
    width: 168px !important;
  }
  .custmImg2.col-lg-4 {
    width: 82px !important;
  }
  .withdrwaRow .withdrw,
  .paymentCards .payment {
    align-items: center !important;
    margin: 18px 0 !important;
  }
  .setting_fileupload {
    width: max-content !important;
  }
  /* .signup_screen_phone {
    width: 100% !important;
  } */

  .SignInpage-section .check {
    width: 100% !important;
  }
  .SignInpage-section button {
    width: 100% !important;
  }
  .SignInpage-section input {
    width: 100% !important;
  }

  .siginInputbox {
    /* width: 520px !important; */
  }
  label {
    margin-left: 0 !important;
  }
  .row_two.row {
    flex-direction: column-reverse !important;
  }
  .row_one.row {
    flex-direction: column-reverse !important;
  }
  .relax-man-row {
    flex-direction: column-reverse !important;
  }
  .userMenu_B
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    border-radius: 20px 0px 20px 20px;
    width: 235px;
    border: 1px solid #1cb735;
    left: 36% !important;
    margin-top: 250px;
  }
  .modal-scroll.undefined.MuiBox-root.css-41redt {
    width: 590px;
  }
  .higheststandard-col2.col-lg-6.col-md-12 {
    display: none;
  }
  .card-cover-img {
    width: 100% !important;
    height: 235px !important;
  }
  .profileImg > img {
    width: 63% !important;
  }
  .assignedProjectCard_row {
    width: 100%;
    margin-inline: 2px;
  }
  span.sgin-line {
    display: none !important;
  }
  .ScrollIcon-div {
    display: none !important;
  }
  .footer-rightside a {
    font-size: 15px;
  }
  .daysLeftInp {
    width: 90%;
    height: 46px;
    padding: 8px;
    border: 1px solid gray;
    border-radius: 5px;
  }
}

@media (max-width: 767px) {
  .latestNew-container {
    max-width: 1000px !important;
  }
  .latestNews-768 {
    width: 50%;
  }
  .footer-leftside .mid {
    text-align: left;
  }
  .chatMessage_sect .logo_tobar {
    padding-left: 2px !important;
  }
  .Dashboardpage-section {
    margin: 0 !important;
  }
  .userMenu_B
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    left: 36% !important;
  }
  .Services .Services-card {
    height: 365px !important;
  }
  .detail_card_box {
    width: 96%;
    margin: 0 11px;
  }
}

@media (max-width: 540px) {
  .aboutsection-video {
    height: 300px !important;
  }
  .img_compo_head > div {
    height: 57px !important;

    width: 100% !important;
  }
  .phone-container,
  .image-multi-container {
    width: 100% !important;
  }

  .signup_screen_phone {
    width: 100% !important;
  }
  .inner-text {
    font-size: 18px;
  }
  .icons-div {
    justify-content: center;
    align-items: center;
  }
  .social-icons-new {
    width: 36px;
    height: 36px;
  }
  .Servicespage-section1 {
    height: 162px;
  }
  .Servicespage-section1-heading {
    height: 150px;
  }
  h2 {
    font-size: 20px !important;
  }
  h5 {
    font-size: 18px !important;
  }
  .breedCum span {
    font-size: 16px !important;
  }
  .tb {
    font-size: 14px !important;
  }
  /* ######Home page starts######## */
  /* .Services .Services-card {
        height: 600px;
    } */
  .onboardprocess-section {
    /* margin-top: 320px !important; */
  }
  .OrderContent {
    margin: 10px 0 !important;
  }
  .NewsCard-section {
    margin: 30px 0 !important;
  }
  .NewsCard .card {
    height: 600px !important;
  }
  .input-box {
    width: 100% !important;
  }
  /* ######Home page ends######## */
  /*############# Our Services page starts ################ */
  a {
    font-size: 15px !important;
    padding: 6px 0;
  }
  /* .con .phone_num {
        width: 43%;
    } */
  .Servicespage-section2-leftside {
    text-align: center;
  }
  .row_one {
    flex-direction: column-reverse;
  }
  /*############# Our Services page ends ################ */
  /*############# Our aboutus page starts ################ */
  .Contact-box {
    width: 100% !important;
  }
  /*############# Our aboutus page ends ################ */
  .Dashboardpage-section {
    margin: 0 !important;
  }
  .Dashboardpage-section {
    overflow-x: scroll;
    width: 100%;
    padding: 0px;
  }
  /* My projects css starts */
  .MyprojectCard-box .project-card {
    width: 100% !important;
  }
  .df-jc-ac {
    width: 89%;
  }
  .dropdown-style-x {
    padding: 5px 8px !important;
  }
  .df-jc-ac {
    width: 98%;
  }
  .btn-detail {
    /* margin-bottom: 10px !important; */
  }
  /* My projects css ends */
  .HowToOrderpage-section4-rightside {
    margin: 50px 0;
  }
  /* #####Sign In Page Starts###### */
  .check {
    width: 450px !important;
  }
  .SignInpage-btn button {
    width: 457px !important;
    height: 50px !important;
  }
  .SignInpage-section1 {
    width: 495px !important;
  }
  /* #####Sign In Page ends###### */
  /* #####Sign Up Page Starts###### */
  .SignUppage-section1 {
    width: 100% !important;
  }
  .SignUppage-btn button {
    width: 300px !important;
    height: 50px !important;
  }
  .SignUp-check {
    width: unset !important;
  }
  .latestNews-768 {
    width: 90%;
    margin: 0 auto;
  }
  .footer-middile {
    margin-left: 0;
  }
  .MyprojectCard-container.col-md-4 {
    width: 90% !important;
    margin: auto !important;
  }
  .assignedProject-Container > .MyprojectCard-box {
    justify-content: center !important;
  }
  .are-you-sure-modal .modal-box {
    /* width: 400px !important; */
  }
  .modal-close-icon {
    z-index: 9999 !important;
    position: relative !important;
  }
  .custm_masterCard .custmImg {
    width: 148px !important;
  }
  .custmImg1.col-lg-4 {
    width: 114px !important;
  }
  .custmImg2.col-lg-4 {
    width: 89px !important;
  }
  .paymentCards {
    padding-left: 0 !important;
  }
  .aboutSection-col {
    padding: 0 !important;
  }
  .row_one.row {
    flex-direction: column !important;
  }
  .footer-leftside img {
    width: 60% !important;
  }
  .Footer-section {
    text-align: center !important;
  }
  .footer-leftside .mid {
    text-align: center;
  }
  .con {
    width: 100% !important;
    margin: auto !important;
    justify-content: center !important;
  }
  .outgoing,
  .incoming {
    max-width: 65% !important;
  }
  .msg_heading {
    margin: 30px -10px !important;
  }
  .btn-detail {
    white-space: nowrap;
  }
  td.c-p {
    white-space: nowrap;
  }
  .userMenu_B
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    left: 27% !important;
  }
  .modal-scroll.undefined.MuiBox-root.css-41redt {
    width: 490px !important;
  }
  .view-more-img-div img {
    width: 100%;
    object-fit: contain;
    height: 350px;
  }
  h5.mt-5.txt {
    margin-top: -1rem !important;
  }
  .aboutSection1-box {
    margin-bottom: 33px !important;
  }
  .contact-phone {
    width: 100% !important;
  }
  span.testimonail-line {
    width: 444px !important;
  }
  .fade.modal.show {
    padding-left: 0 !important;
  }
  .Services .Services-card {
    height: 470px !important;
  }
  .card-cover-img {
    width: 100% !important;
    height: 335px !important;
  }
  .latestNews-img-box {
    width: 100% !important;
    height: 318px !important;
  }
  .profileImg > img {
    width: 85% !important;
  }
  .assignedProjectCard_row {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  /* #######Home page starts######### */
  .HeroSection {
    height: calc((66vh - 109px) - 87px);
  }
  .Contact-box {
    padding: 10px !important;
    margin: 20px auto !important;
  }
  .NewsCard .card {
    height: 570px !important;
  }
  .Contact {
    margin: 0px !important;
  }
  /* #######Home page ends######### */
  /* #####Sign In Page Starts###### */
  .SignInpage-section1 {
    width: 90% !important;
  }
  .SignInpage-btn button {
    width: 100% !important;
  }
  .check {
    width: 420px !important;
  }
  /* #####Sign In Page ends###### */
  .SignUppage-btn button {
    width: 200px !important;
  }
  .social-icons {
    font-size: 20px !important;
  }
  .rightside {
    width: 50% !important;
  }
  .are-you-sure-modal .modal-box {
    /* width: 360px !important; */
  }
  .modal-close-icon {
    z-index: 9999 !important;
    position: relative !important;
  }
  .are-you-sure-modal .modal-box h5 {
    /* text-align: left !important; */
  }
  .are-you-sure-modal > .container > .row > * {
    /* -bs-gutter-x: 0p !important; */
    padding-left: 0 !important;
    margin: 0 !important;
  }
  .custm_masterCard .custmImg {
    width: 124px !important;
  }
  .custmImg1.col-lg-4 {
    width: 114px !important;
  }
  .custmImg2.col-lg-4 {
    width: 53px !important;
  }
  .outgoing,
  .incoming {
    max-width: 75% !important;
  }
  .send_msg input::placeholder {
    font-size: 13px !important;
  }
  .modal-scroll.undefined.MuiBox-root.css-41redt {
    width: 438px !important;
  }
  .view-more-img-div img {
    height: 296px;
  }
  .contact-phone {
    width: 100% !important;
  }
  .detail_card_box {
    width: 96%;
    margin: 0 8px;
  }
}

@media (max-width: 420px) {
  h1 {
    font-size: 19px !important;
  }
  .mid.project-card.c-p h5 {
    font-size: 15px !important;
  }
  /* .mid {
        margin-right: -20px;
    } */
  .projectCard-box .discript_heading {
    font-size: 14px;
    font-weight: 700;
  }
  .projectCard-box .project-card {
    margin: unset !important;
    z-index: -1;
  }
  /* Footer section starts */
  /* .con .phone_num {
        width: 55%;
    } */
  .copyright-text .mid {
    margin-top: 20px;
  }
  /* Footer section ends */
  /* Add project css starts */
  .custom-image-upload {
    height: 180px !important;
    width: 80.5% !important;
    margin: 10px auto !important;
  }
  .addProjectHeading {
    text-align: center;
  }
  .image-multi-container {
    margin: 10px 0 !important;
  }
  .btn-box > button {
    margin: 0 auto !important;
  }
  /* Add project css ends */
  /* .MyprojectCard-box .project-card {
        width: 90% !important;
    } */
  /* Notification css starrts  */
  .Notification-text {
    font-size: 15px !important;
  }
  .Notification-avatar img {
    width: 50px !important;
    height: 50px !important;
  }
  /* Notification css ends */
  /* Home page starts */
  .input-box {
    width: 100% !important;
  }
  .NewsCard .card {
    height: 540px !important;
  }
  .OrderImg {
    width: unset !important;
  }
  /* Home page ends */
  /* #####Sign In Page Starts###### */
  .SignInpage-section1 {
    width: 394px !important;
  }
  .check {
    width: 340px !important;
  }
  .SignInpage-btn button {
    width: 346px !important;
  }
  .aboutSection-text {
    width: 97% !important;
  }
  .aboutSection-img {
    width: 97% !important;
  }
  .are-you-sure-modal .modal-box {
    /* width: 320px !important; */
  }
  .are-you-sure-modal .modal-box h5 {
    font-size: 16px !important;
    /* text-align: left !important; */
  }
  .projectCard-box .project-card {
    max-width: 98% !important;
    margin: 10px auto !important;
  }
  .custm_masterCard .custmImg {
    width: 120px !important;
  }
  .custmImg1.col-lg-4 {
    width: 114px !important;
  }
  .custmImg2.col-lg-4 {
    width: 53px !important;
  }
  .payment_col > .btn-detail {
    padding: 11px 7px !important;
  }
  .payment > .small {
    font-size: 18px !important;
  }
  .paymentCards .balanceCard,
  .withdrwaRow .balanceCard {
    padding: 7px 0px !important;
    min-height: 227px !important;
  }
  .paymentCards .balanceCard {
    max-height: 238px !important;
  }
  /* #####Sign In Page ends###### */
  .chatMessage_sect .logo_tobar {
    padding: 15px 0px;
  }
  .modal-scroll.undefined.MuiBox-root.css-41redt {
    width: 380px !important;
  }
  /* .view-more-img-div img {
        height: 250px;
    } */
  .view-more-img-div img {
    width: 100%;
    height: 192px !important;
    object-fit: contain !important;
  }
  .contact-phone {
    width: 100% !important;
  }
  span.testimonail-line {
    width: 380px !important;
  }
  .latestNews-img-box {
    width: 100% !important;
    height: 280px !important;
  }
  .latestNewsTitle {
    height: 24% !important;
  }
  .scrollMsgs {
    /* overflow-y: scroll;
        height: 54vh; */
    width: 100%;
  }
}

@media (max-width: 375px) {
  /* #####Sign In Page Starts###### */
  .SignInpage-section1 {
    width: 348px !important;
  }
  /* #####Sign In Page ends###### */
  .latestNewsTitle {
    height: 20% !important;
  }
  .NewsCard .card {
    height: 470px !important;
  }
  .Services .Services-card {
    height: 460px !important;
  }
  .Dashboardpage-project-btn button,
  .Dashboardpage-invoice-btn button {
    padding: 8px 10px !important;
  }
  .detail-box > .btn-detail {
    padding: 10px 9px !important;
  }
  .are-you-sure-modal .modal-box {
    /* width: 280px !important; */
  }
  .are-you-sure-modal .modal-box .btns-box .yes-btn {
    font-size: 14px !important;
    /* padding: 11px 7px !important; */
    margin-left: 5px !important;
  }
  .modal-close-icon {
    padding: 0px 0px !important;
    border-radius: 4px !important;
  }
  .are-you-sure-modal > .container > .row > * {
    /* -bs-gutter-x: 0p !important; */
    padding-left: 0 !important;
    margin: 0 !important;
  }
  .custm_masterCard .custmImg {
    width: 120px !important;
  }
  .custmImg1.col-lg-4 {
    width: 114px !important;
  }
  .custmImg2.col-lg-4 {
    width: 48px !important;
  }
  .payment_col > .btn-detail {
    padding: 8px 5px !important;
  }
  .payment > .small {
    font-size: 15px !important;
  }
  .custm_masterCard .deleteIcon {
    padding: 3px !important;
    width: 30px !important;
    height: 30px !important;
  }
  .custm_masterCard {
    padding: 40px 10px !important;
  }
  .check {
    width: 307px !important;
  }
  .outgoing,
  .incoming {
    max-width: 88% !important;
  }
  .userMenu_B
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    left: 19% !important;
  }
  .modal-scroll.undefined.MuiBox-root.css-41redt {
    width: 350px !important;
  }
  .view-more-img-div img {
    height: 250px;
  }
  .contact-phone {
    width: 100% !important;
  }
  .SignInpage-btn button {
    width: 306px !important;
  }
  span.testimonail-line {
    width: 320px !important;
  }
  .card-cover-img {
    width: 100% !important;
    height: 242px !important;
  }
  .latestNews-img-box {
    width: 100% !important;
    height: 215px !important;
  }
  .card_div > .nullData-box p {
    font-size: 16px !important;
  }
  .custm_masterCard .custmImg {
    width: 109px !important;
  }
  /* .custmImg1.col-lg-4 {
        width: 114px !important;
    } */
  .custmImg2.col-lg-4 {
    width: 41px !important;
  }
  .custm_masterCard {
    padding: 40px 20px !important;
  }
}

@media (max-width: 360px) {
  h2 {
    font-size: 14px !important;
  }
  .mid.project-card.c-p h5 {
    font-size: 15px !important;
  }
  .OrderContent .mid {
    margin-bottom: 20px !important;
  }
  .projectCard-box .project-card {
    width: 100% !important;
  }
  .Dashboardpage-section {
    position: relative;
  }
  .Dashboardpage-links {
    position: absolute;
    left: -18px;
  }
  /* Footer section starts */
  .con .phone_num {
    /* width: 66%; */
    /* Footer section ends */
  }
  /* Notification css starrts  */
  .Notification-text {
    font-size: 14px !important;
  }
  .Notification-avatar img {
    width: 40px !important;
    height: 40px !important;
  }
  /* Notification css ends */
  /* #####Sign In Page Starts###### */
  .SignInpage-section1 {
    width: 332px !important;
  }
  /* #####Sign In Page ends###### */
  .form-check-label {
    font-size: 16px !important;
  }
  .green {
    font-size: 16px !important;
  }
  .Dashboardpage-project-btn button,
  .Dashboardpage-invoice-btn button {
    padding: 6px 7px !important;
    font-size: 13px !important;
  }
  .are-you-sure-modal .modal-box {
    /* width: 270px !important; */
  }
  .custm_masterCard .custmImg {
    width: 115px !important;
  }
  .custmImg1.col-lg-4 {
    width: 114px !important;
  }
  .custmImg2.col-lg-4 {
    width: 48px !important;
  }
  .modal-content {
    padding: 20px 5px !important;
  }
  .custm_masterCard {
    padding: 40px 20px !important;
  }
  .modal-scroll.undefined.MuiBox-root.css-41redt {
    width: 320px !important;
  }
  .view-more-img-div img {
    height: 220px;
  }
  .SignInpage-btn button {
    width: 291px !important;
  }
  .check {
    width: 291px !important;
  }
  .latestNewsTitle {
    height: 23% !important;
  }
  .detail_card_box {
    width: 96%;
    margin: 0px 6px;
  }
}

@media (max-width: 320px) {
  .aboutsection-video {
    height: 250px !important;
  }
  /* .con .phone_num {
        width: 76%;
    } */
  /* .MyprojectCard-box .project-card {
        width: 95% !important;
    } */
  /* #####Sign In Page Starts###### */
  .SignInpage-section1 {
    width: 296px !important;
  }
  /* #####Sign In Page ends###### */
  .Services .Services-card {
    height: 400px !important;
  }
  .rightside {
    width: 80% !important;
    text-align: center !important;
  }
  .projectCard-box .project-card {
    padding: 12px !important;
    height: 330px !important;
  }
  /* .projectCard-box .project-card {
        max-width: 98% !important;
        margin: 10px auto !important;
    } */
  .are-you-sure-modal .modal-box {
    /* width: 220px !important; */
  }
  .are-you-sure-modal .modal-box h5 {
    font-size: 13px !important;
  }
  .modal-content {
    padding: 20px 0px !important;
  }
  .are-you-sure-modal .modal-box {
    /* width: 270px !important; */
  }
  .custm_masterCard .custmImg {
    width: 106px !important;
  }
  .custmImg1.col-lg-4 {
    width: 99px !important;
  }
  .custmImg2.col-lg-4 {
    width: 43px !important;
  }
  .custmImg1 > p.small {
    font-size: 10px !important;
  }
  /* .check {
        width: 245px !important;
    } */
  .userMenu_B
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
    left: 13% !important;
  }
  .modal-scroll.undefined.MuiBox-root.css-41redt {
    width: 300px !important;
  }
  .view-more-img-div img {
    height: 159px !important;
  }
  .contact-phone {
    width: 65% !important;
  }
  .btn-detail {
    padding: 15px 14px !important;
    /* margin: 20px 5px; */
  }
  .check {
    width: 255px !important;
  }
  .SignInpage-btn button {
    width: 258px !important;
  }
  span.testimonail-line {
    width: 260px !important;
  }
  .card-cover-img {
    width: 100% !important;
    height: 188px !important;
  }
}

@media (min-width: 1600px) {
  .HeroSection {
    height: calc((100vh - 109px) - 103px);
  }
  .higheststandard-con {
    height: 100%;
  }
  .higheststandard-row {
    height: 100%;
  }
  .higheststandard-section2 {
    width: 624px !important;
    /* height: 739px !important; */
    padding: 0px !important;
  }
  .SignInpage-section {
    height: calc(100vh - 84px);
  }
  .SignInpage-section .container {
    height: 100%;
  }
  .SignInpage-section .row {
    height: 100%;
  }
  .SignInpage-section .col-lg-12.col-md-12 {
    display: flex;
    align-items: center;
  }
}
