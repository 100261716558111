.BlogMainSection {
  width: 100%;
  height: 100%;
}

.mainContainer {
  max-width: 90%;
}
.header {
  color: var(--text-color-black);
  margin-bottom: 20px;
}
.description {
  color: var(--text-dark-gray);
  margin-bottom: 20px;
  word-break: break-all;
}
.date {
  color: var(--text-dark-gray);
  margin-bottom: 45px;
}
.loaderBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 230px);
}
.blogCard_div{
  margin: 50px auto;
}
/* @media (min-width: 1440px) {
  .mainContainer {
    max-width: 80%;
  }
} */
/* @media (max-width: 120px) {
  .mainContainer {
    max-width: 80%;
  }
} */

@media (max-width: 1280px) {
  .blogCard_div{
    margin-bottom: 50px;
    max-width: 90%;
    margin: 50px auto;
  }
}
@media (max-width: 992px) {
  .blogCard_div{
    max-width: 100%;
   
  }
}
@media (max-width: 578px) {
  .header {
    font-size: 36px;
  }
}
