:root {
  --dark-gray: #4a4a4a;
  --secondary-gray: #707070;
  --white: #fff;
  --dark-pink: #818181;
}
.cus_b_chat_head{
  display: flex;
    justify-content: space-between;
}

.chatMessage_sect {
  
  /* margin: 50px; */
}

.message_sidebar {
  background: var(--dark-gray);
  border-radius: 15px;
  padding: 9px 0px;
}

.sidebar_scroll {
  height: calc(54vh + 145px);
  padding: 10px 0;
  overflow-y: scroll;
}

.sidebar_scroll::-webkit-scrollbar {
  background: var(--dark-pink);
  width: 6px;
  height: 30%;
  border-radius: 19px;
}

.message_sidebar .sidebar_notification_msg {
  background-color: var(--dark-pink);
  padding: 16px;
  margin: 15px 10px;
  border-radius: 15px;
}

.message_sidebar .sidebar_notification_msg h6,
.sp {
  color: var(--white);
}

.chatMessage_sect .logo_tobar {
  background-color: var(--dark-gray);
  border-radius: 15px;
  padding: 15px 20px;
}

.chatMessage_sect .logo_tobar .logo_text h6,
.xsmall {
  color: var(--white);
}

.chatMessage_sect .logo_main {
  display: flex;
  align-items: center;
  border-bottom: 1px dotted var(--white);
  padding: 12px 0;
}

.chatMessage_sect .logo_main .logo_text {
  height: 45px;
  margin: 0 8px;
}

.logo_tobar .inbox_chat {
  background-color: var(--dark-pink);
  margin-top: 30px;
  border-radius: 15px;
  padding: 11px;
}

.logo_tobar .inbox_chat p.small {
  color: var(--white);
  padding: 6px;
}

.logo_tobar .inbox_chat .msg_timing {
  color: var(--white);
  padding: 0 4px;
}

:is(.outgoing, .incoming) .inbox_chat {
  position: relative;
  margin-block-end: 10px;
  clear: both;
}

:is(.outgoing, .incoming) .inbox_chat::before {
  content: "";
  height: 10px;
  width: 20px;
  position: absolute;
  bottom: -10px;
  background: inherit;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: rotate(180deg);
}

.incoming .inbox_chat::before {
  left: 30px;
}

.outgoing .inbox_chat::before {
  right: 30px;
}

.outgoing .msg_timing {
  float: right;
}

.incoming {
  width: 40%;
}

.outgoing {
  width: 40%;
}

.logo_tobar .outgoing_chat {
  background-color: var(--secondary-gray);
}

.send_msg_field .send_msg input {
  width: 100%;
  background-color: var(--dark-pink);
  border: unset;
  border-radius: 10px;
  padding: 12px;
  color: var(--white);
}

.send_msg_field .send_msg input::placeholder {
  color: var(--white);
  font-size: 16px;
}

.send_msg_field .send_msg {
  position: relative;
}

.send_msg_field button {
  position: absolute;
  right: 22px;
  bottom: 6px;
  font-size: 30px;
  color: var(--white);
  background: transparent;
}



.scrollMsgs {
  overflow-y: scroll;
  height: 54vh;
}

.scrollMsgs::-webkit-scrollbar {
  background: var(--dark-pink);
  width: 6px;
  height: 30%;
  border-radius: 19px;
}

.chatMessage_sect .msg_heading h2 {
  margin-bottom: 20px;
}
