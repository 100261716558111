.aboutSection1{
    margin: 100px 0;
    text-align: center;
}
.aboutSection-text{
    width: 55%;
    box-shadow: 0px 0px 5px #70c5c3;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 5% 4%;
}
.aboutSection1-box{
    position: relative;
    margin-bottom: 300px;
    
}
.aboutSection-img{
    width: 55%;
    position: absolute;
    object-fit: cover;
    object-position: center center;
    right: 1%;
    top: 27%;
    z-index: -1;
}
.aboutSection-img img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.aboutSection-work{
    text-align: center;
    margin: 50px auto;
    max-width: 825px;
}
.aboutsection-video{
    width: 100%;
    margin: 50px 0;
}
.aboutsection-video img{
    width: 100%;
}
.meet-img-box{
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    cursor: pointer;
    
}
.meet-img-box img{
    width: 100%;
}
.meet-img-outer-box {
    display: flex;
    justify-content: center;
}
.meet-img-inner-box{
 position: absolute;
 width: 80%;
    /* height: 156px; */
    background-color: #1CB735;
    opacity: 0.9;
    color: #FFFFFF;
    bottom: -100%;
    /* left: 9%; */
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: linear all .8s;
}

.meet-img-box:hover .meet-img-inner-box{
    bottom: 7%;
    cursor: pointer;
}
.share-icon{
    font-size: 14px !important;
    position: absolute;
    right: 0%;
    top: 10px;
}
.meet-img-inner-box-text .meet-text{
     overflow: hidden;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 3;
}

.meet-img-inner-box .social-icons{
    color: #FFFFFF;
    font-size: 18px;
    padding: 0 !important;
    margin: 0 !important;
    margin-right: 10px !important;
}
.aboutSection-Meet{
    background-color: #1CB735;
    padding: 50px 0;
}
.aboutSection-Meet-text{
    text-align: center;
    color: white;
    padding: 20px 0;
    max-width: 978px;
    margin: auto;
}
.Contactus-section {
    max-width: 960px;
}
.meet-img-mainbox{
    display: flex;
    justify-content: space-between;

}
.aboutsection-video{
    width: 100%;
    height: 400px;
    border-radius: 10px;
}
/* .rec.rec-arrow-right:hover {
    background-color:#1cb735 !important
}
.rec.rec-arrow-left:hover {
    background-color:#1cb735 !important
}
.rec.rec-arrow-right:focus {
    background-color:#1cb735 !important
}
.rec.rec-arrow-left:focus { 
    background-color:#1cb735 !important
} */

.aboutSection .rec.rec-arrow-left{
    background-color: #1CB735 !important;
    color: #FFFFFF !important;
}
.aboutSection .rec.rec-arrow-right{
    background-color: #1CB735 !important;
    color: #FFFFFF !important;
}

@media screen and (max-width: 1400px) {
    .aboutSection-text{
        width: 55%;
    }
    .aboutSection-img{
        width: 52%;
        height: 96%;
        right: 0%;
        top: 35%;
    }
  }