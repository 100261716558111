.responsiveSideBarMenu {
    width: 100%;
    overflow-x: scroll;
    padding: 0 20px;
}

.responsiveSideBarMenu .responsiveActive-link {
    color: #1cb735;
    border-bottom: 2px solid #1cb735;
    border-radius: unset;
}

.responsiveSideBarMenu ul {
    display: flex;
    padding-top: 15px;
    margin-bottom: 5px;
}

.responsiveSideBarMenu ul li {
    margin-right: 22px;
    list-style: none;
    width: 100%;
    font-size: 14px;
}

.responsiveSideBarMenu ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: max-content;
    padding-bottom: 5px;
    text-decoration: none;
}

.custom-btn.memuBar {
    display: flex;
}

.responsiveSideBarMenu>ul {
    padding-left: 0 !important;
}


/* @media (max-width:768px) {
    .display-sm-none {
        display: none;
    }
    .display-md-none {
        display: block;
    }
}

@media (min-width:769px) {
    .display-sm-none {
        display: block;
    }
    .display-md-none {
        display: none;
    }
} */

@media (max-width:768px) {
    .display-sm-none {
        display: none;
    }
    .display-md-none {
        display: block;
    }
}

@media (min-width:991px) {
    .display-sm-none {
        display: block;
    }
    .display-md-none {
        display: none;
    }
}

@media (max-width:992px) {
    .display-sm-none {
        display: none;
    }
    .display-md-none {
        display: block;
    }
}