:root {
    --dark-gray: #4a4a4a;
    --secondary-gray: #707070;
    --white: #fff;
    --dark-pink: #818181;
}

.chatMessage_sect {
    /* margin: 50px; */
}

.message_sidebar {
    background: var(--dark-gray);
    border-radius: 15px;
    padding: 9px 0px;
}

.sidebar_scroll {
    height: calc(54vh + 145px);
    padding: 10px 0;
    overflow-y: scroll;
}

.sidebar_scroll::-webkit-scrollbar {
    background: var(--dark-pink);
    width: 6px;
    height: 30%;
    border-radius: 19px;
}

.logo_tobar > label {
    cursor: auto !important;
    border: unset !important;
    outline: none !important;
  }

.scrollMsgs::-webkit-scrollbar-thumb {
    background-color: #bfbebe;
}

.message_sidebar .sidebar_notification_msg {
    background-color: var(--dark-pink);
    padding: 16px;
    margin: 15px 10px;
    border-radius: 15px;
}

.message_sidebar .sidebar_notification_msg h6,
.sp {
    color: var(--white);
}

.chatMessage_sect .logo_tobar {
    background-color: var(--dark-gray);
    border-radius: 15px;
    padding: 15px 15px;
}

.chatMessage_sect .logo_tobar .logo_text h6,
.xsmall {
    color: var(--white);
}

.chatMessage_sect .logo_main {
    display: flex;
    align-items: center;
    border-bottom: 1px dotted var(--white);
    padding: 12px 0;
    margin-bottom: 10px;
}

.chatMessage_sect .logo_main .logo_text {
    height: 45px;
    margin: 0 8px;
}

.logo_tobar .inbox_chat {
    background-color: var(--dark-pink);
    /* margin-top: 30px; */
    border-radius: 10px;
    padding: 10px;
}

.logo_tobar .inbox_chat p.small {
    color: var(--white);
    /* padding: 6px; */
    margin: 0px;
    word-break: break-word;
}

.logo_tobar .inbox_chat .msg_timing {
    color: var(--white);
    padding: 0 4px;
}

 :is(.outgoing, .incoming) .inbox_chat {
    position: relative;
    margin-block-end: 10px;
    clear: both;
}

 :is(.outgoing, .incoming) .inbox_chat::before {
    content: "";
    height: 10px;
    width: 20px;
    position: absolute;
    bottom: -10px;
    background: inherit;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    transform: rotate(180deg);
}

.incoming .inbox_chat::before {
    left: 30px;
}

.outgoing .inbox_chat::before {
    right: 30px;
}

.outgoing .msg_timing {
    float: right;
}

.incoming {
    width: max-content;
    max-width: 40%;
}

.outgoing {
    width: max-content;
    max-width: 40%;
}

.logo_tobar .outgoing_chat {
    background-color: var(--secondary-gray);
}

.send_msg_field .send_msg input {
    width: 90%;
    background-color: transparent;
    border: unset;
    border-radius: 10px;
    padding: 10px 0px 10px 10px;
    color: var(--white);
    outline: unset;
}

.send_msg_field .send_msg input::placeholder {
    color: var(--white);
    font-size: 16px;
}

.send_msg_field .send_msg {
    position: relative;
    background-color: var(--dark-pink);
    border-radius: 10px;
    display: flex;
    /* justify-content: space-evenly; */
    align-items: center;
}

.send_msg_field button {
    font-size: 22px;
    color: var(--white);
    background: transparent;
}

.scrollMsgs {
    overflow-y: scroll;
    height: 54vh;
    /* width: 100%; */
}

.scrollMsgs::-webkit-scrollbar {
    background: var(--dark-pink);
    width: 6px;
    height: 30%;
    border-radius: 19px;
}

.chatMessage_sect .msg_heading h2 {
    margin-bottom: 20px;
}

.msg_timing {
    color: #bfbebe;
    font-size: 12px;
}

.messageContainer {
    /* padding-bottom: 25px; */
}

.chatLoadMoreBtnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.loaderContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loaderContainer>span {
    color: white;
    font-size: 18px;
    font-weight: 500;
    margin-top: 5px;
    text-transform: capitalize;
}

.loaderContainer .spinner-grow {
    width: 3rem;
    height: 3rem;
}

.btn-file {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 20px;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
}

.btn-file>svg {
    font-size: 22px;
    color: var(--white);
    background: transparent;
    /* z-index: 99999; */
}

.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.w-50-image {
    width: 60px;
    object-fit: contain;
    cursor: pointer;
    min-height: 60px;
}

.chats-container {
    /* width: 933px !important; */
}

.hide-chat-container {
    visibility: hidden;
}

.we-have-file {
    /* height: 200px; */
    background-color: var(--dark-pink);
    position: absolute;
    color: #fff;
    bottom: 0;
}

.sc-pVTFL.bMoeeU {
    /* height: 200px; */
    position: absolute !important;
    bottom: 0px;
    background-color: transparent;
}

.logo_tobar {
    position: relative;
}
/* 
.sc-furwcr.ehGMMA {
    width: 98%;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
} */

.send_msg_field_2 {
    display: none;
}