.NewsCard-section {
    text-align: center;
    margin: 50px 0 70px 0;
}

.NewsCard span .clock-icon {
    color: #A8A8A8;
    font-size: 20px;
}

.NewsCard .small {
    /* overflow: hidden;
     display: -webkit-box;
     -webkit-box-orient: vertical;
     -webkit-line-clamp: 3; */
}
.latestNews-img-box {
    width: 100%;
    height: 290px;
}
.latestNews-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
}

.time {
    font-size: 16px;
    color: #A8A8A8;
    padding: 10px;
    margin-top: 20px;
}

.NewCardsbtn {
    text-align: center;
    margin: 50px;
}

.NewCardsbtn button {
    padding: 10px 30px;
}

.NewsCard .mid {
    color: #1CB735;
}

.NewsCard .small {
    line-height: 1.5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: #AEAEAE;
}

.NewsCard .mid a {
    text-decoration: none;
    color: #1CB735;
}

.NewsCard .card {
    border-radius: 10px;
    box-shadow: 3px 1px 7px grey;
    width: 100%;
    height: 580px;
}