.blogCardMainContainer {
  border: 1px solid var(--header-orange-color);
  background-color: var(--white-color);
  /* margin: 50px 0; */
}
.blogCardImageContainer {
  width: 100%;
  height: 491px;
  max-width: 100%;
  /* background-color: aqua; */
}

.blogCardImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.invoice_btn{
  padding: 12px;
}
.blogCardDateContainer span {
  color: var(--main-color);
  font-size: 18px;
  margin-right: 20px;
}
.blogCardDateContainer {
  margin: 10px 0px;
  /* margin-top: 42px;
  margin-bottom: 27px; */
}
.blogCardContainer {
  padding: 40px 0px;
  width: 100%;
}
.blogCardContainer h3 {
}
.blogCardContainer p {
  /* margin-bottom: 10px;
  padding: 0 20px 0 0;
  line-height: 2;
  font-family: "helvetica-regular";
  word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8; */
    /* position: relative;
  display: inline-block;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 250px; 
  line-height: 2;
  
    font-family: "Poppins Regular"; */
}
/* .text {
  position: relative;
  font-size: 14px;
  color: black;
  width: 100%; 
} */

/* .text.ellipsis::after {
  content: "...";
  position: absolute;
  right: -12px; 
  bottom: 4px;
} */
.description_para:first-child{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    margin-bottom: 10px;
  padding: 0 20px 0 0;
  line-height: 2;
  font-family: "Poppins Regular";
  /* word-break: break-all; */

}
.blogDate{
  font-size: 16px !important;
  color: gray !important;
}
@media screen and (max-width: 1280px) {
  .blogCardImageContainer {
    max-width: 100%;
    margin: auto;
  }
  .blogCardContainer {
    max-width: 100%;
    margin: auto;
  }
  .blogCardMainContainer {
    margin: 0;
  }
  .blogCardContainer{
    /* padding-left: 0 !important; */
  }
 
}
@media screen and (max-width: 1024px) {

  .blogCardContainer h3 {
    max-width: 520px;
    font-size: 26px !important;
  }
}
@media screen and (max-width: 992px) {
  .blogCardImageContainer {
    max-width: 90%;
    height: 400px;
    margin: auto;
  }
  .blogCardContainer {
    max-width: 90%;
    margin: auto;
  }
  .blogCardContainer{
    padding-left: 0 !important;
  }
  .blogDate{
    font-size: 14px !important;
  }
}
@media (max-width: 768px) {
  .blogCardImageContainer {
    height: 400px;
  }
  
  .blogCardMainContainer {
    margin: 0;
  }
}
@media (max-width: 578px) {
  .blogCardImageContainer {
    height: 260px;
  }
}
