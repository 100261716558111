.testimonial {
  margin: 120px 0 0 0;
  /* background-color: #ebf8eb; */
  padding: 50px 0;
}
.testimonial-section {
  text-align: center;
  max-width: 719px;
  text-align: center;
  margin: auto;
}
.testimonial-section h1 {
  margin-bottom: 20px;
}
.testimonial-section-stars {
  text-align: center;
  margin-top: 20px;
}
.stars {
  font-size: 30px;
  color: #1cb735 !important;
  padding: 5px;
}

.testimonial-section-profileImg .row {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* border-top: 2px solid gray; */
  /* margin-top: 50px; */
  padding-top: 50px;
}
span.testimonail-line {
  width: 585px;
  margin: 60px auto 0 auto;
  border-top: 1px solid #a8a8a88a;
}
.profileImg {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profileImg > img {
  width: 40%;
  height: auto;
  border-radius: 100px;
}
.profileImg-center {
  padding: 0;
}
.profilenames {
  text-align: center;
  padding: 20px;
}
.profilenames span {
  padding-left: 10px;
  font-size: 20px;
}

@media (min-width: 992px) {
  .col-lg-1.coll_one {
    flex: 0 0 auto;
    width: 11.333333%;
  }
}
@media (min-width: 768px) {
  .col-lg-1.coll_one {
    flex: 0 0 auto;
    width: 15%;
  }
  .col-lg-2.coll_two{
    width: 21.333333%;
  }
}
@media (min-width: 767px) {
  .col-lg-1.coll_one {
    flex: 0 0 auto;
    width: 15%;
  }
  .col-lg-2.coll_two{
    width: 21.333333%;
  }
}
@media (max-width:540px) {
  .col-lg-1.coll_one {
    flex: 0 0 auto;
    width: 15%;
  }
  .col-lg-2.coll_two{
    width: 21.333333%;
  }
}
@media (max-width:480px) {
  .col-lg-1.coll_one {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-2.coll_two{
    width: 30%;
  }
}
