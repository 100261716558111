.Contact{
    margin: 50px 0;
}
.Contactus-section{
    text-align: center;
    margin: 50px 0 ;
    max-width: 800px;
    text-align: center;
    margin: 50px auto;
}
.Contact-box{
    width: 704px;
    box-shadow: 0px 10px 50px rgb(28 183 53 / 20%);
    padding: 50px;
    margin-bottom: 50px;
    margin: 0 auto;
    border-radius: 10px;
}
.input-box{
    padding: 20px;
    margin-top: 10px !important;
}
.Contactbtn{
   text-align: center;
   
}
.Contactbtn button{
padding: 10px 20px;
}
@media screen and (max-width:1440px){
    .Contact-box{
        width: 500px;
    }
    .input-box{
        width: 400px;
        height: 40px;
    }
}
