.Servicespage-section1 {
    /* background-image: url('../images/Group 548.png'); */
    background-size: cover;
    background-position: bottom center ;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    background-color: #1CB735;
    /* background-image: linear-gradient(142deg, #1CB735, #b2b031); */
}

.Servicespage-section1-heading {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* ###Asif Css## */

.Servicespage-section2-leftside {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    height: 100%;
}

.Servicespage-section2-leftside,
.Servicespage-section2-rightside {
    margin: 40px 0;
}


/* ###Asif Css## */

.Servicespage-section2 {
    margin: 100px 0;
}

.Servicespage-section2-rightside {
    width: 100%;
}

.Servicespage-section2-rightside img {
    width: 100%;
    border-radius: 10px;
}

.Servicespage-btns button {
    padding: 10px 20px;
}

.Servicespage-section3 {
    padding: 100px 0;
    background-color: #1CB735;
    color: #fff;
}

.Servicespage-section3-heading {
    text-align: center;
    max-width: 954px;
    margin: auto;
}

.Servicespage-section3 {
    text-align: center;
}

.Servicespage-section3-box {
    margin: 50px 0;
    /* padding: 20px; */
    max-width: 332px;
    margin: auto;
}

.Servicespage-section4 {
    text-align: center;
    margin: 50px 0;
}

.Servicespage-section4-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 288px;
    height: 348px;
    border: 1px solid #1CB735;
    border-radius: 20px;
    margin-top: 50px;
}

.Servicespage-section4-img {
    width: 180px;
    height: 272px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Servicespage-section4-img img {
    width: 100%;
    height: auto;
}

.Servicespage-btns2 {
    margin: 50px 0;
}

.Servicespage-btns2 button {
    padding: 10px 20px;
}
.Servicespage-section4-heading {
    max-width: 949px;
    margin: auto;
}