p.small {
    font-size: 14px;
}

h5.small {
    font-size: 24px;
}

th {
    font-size: 16px;
}

td {
    font-size: 15px;
}

.paymentCards {
    padding-left: 22px;
}

.withdrwaRow .widthdrawl_btn,
.paymentCards .payment_btn {
    background-color: #0B30A9;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 50px;
    font-size: 18px;
    padding: 10px 30px;
}

.withdrwal_col {
    text-align: right;
}

.withdrwaRow .paymentMethod {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.paymentCards .balanceCard {
    max-height: 280px;
    overflow-y: scroll;
}

.paymentCards .balanceCard::-webkit-scrollbar {
    width: 5px;
    height: 0px;
}

.paymentCards .balanceCard::-webkit-scrollbar-thumb {
    background: #0000004f;
    border-radius: 20px;
}

.withdrwaRow .balanceCard h5 {
    margin-left: 0px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}

.withdrwaRow .balanceCard p {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.withdrwaRow .balanceCard .usd {
    font-size: 18px;
}

.paymentCards .balanceCard,
.withdrwaRow .balanceCard {
    box-shadow: 0 0px 15px -3px rgb(0 0 0 / 7%), 0 0px 6px -2px rgb(0 0 0 / 5%);
    border-radius: 20px;
    padding: 36px 28px;
    background: #fff;
    min-height: 290px;
}

.custm_masterCard .deleteIcon {
    background: red;
    padding: 4px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    text-align: center;
    margin-left: auto;
}

.custm_masterCard .custmImg {
    display: flex;
    justify-content: space-evenly;
}

.custmImg>div:last-child>p,
.custmImg+div>p {
    margin: 0;
}

.balanceCard .borderRow {
    border-top: 1px solid #EBEBEB;
    padding-top: 12px;
}

.withdrwaRow .withdrw,
.paymentCards .payment {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 30px 0;
}

.custm_masterCard{
    padding: 40px 20px;
    /* border-bottom: 1px solid gray; */
   
}

.custm_masterCard_Active{
 box-shadow: 1px 1px 10px gainsboro;
}

.custm_masterCard:last-child {
    border: none;
}

.custm_masterCard>div {
    display: flex;
    align-items: center;
}

.custm_masterCard>div>div {
    display: flex;
    flex-direction: column;
}

.custm_masterCard .custmImg {
    flex-direction: row;
    align-items: center;
}

.mywalletPara {
    margin-top: 10px;
}


/* Table css starts */

.invoice_activity h5 {
    margin: 20px 0;
    font-family: "Poppins", sans-serif;
}

.custmTable {
    height: unset;
}

.custmTable thead tr th,
.AppointmentTables tbody tr td {
    padding: 17px;
}

.custmTable th,
td {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}


/* Table css ends */


/* Modal Css Starts here */

.mainRow_modal {
    margin-top: 40px;
}

.mainRow_modal .mainRow_border {
    border-right: 1px dotted #7d7d7d;
    ;
}

.modal-lg,
.modal-xl {
    /* max-width: 1100px; */
}

.custmModal_Body h5 {
    text-align: center;
    margin-bottom: 30px;
    color: #000;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.main_cont .balacnceAmount p {
    color: #000000;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-size: 17px;
}

.custmRadioBtn .mainWithdrawl_Label {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.paymentMethod {
    margin-top: 20px;
}

.custmCredit_Withdraw p.big,
.withdrawl_inner p.big,
.payment_statusCont p.big,
.paymentMethod p.big {
    color: #000;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
}

.modal_nextBtn {
    text-align: center;
    margin: 20px 0;
}

.modal_nextBtn>button {
    background-color: #0B30A9;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 50px;
    font-size: 18px;
    padding: 11px 71px;
}

.prevBtn>button {
    background-color: #0B30A9;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 50px;
    font-size: 16px;
    padding: 5px 20px;
}

.mainRow_modal .prevBtn {
    margin-top: -32px;
}

.stepperModal {
    position: relative;
}

.step0 {
    position: absolute;
    left: 30%;
}

.step1 {
    position: absolute;
    right: 30%;
}


/* profile */

.withdrawModal .prevBtn {
    margin-top: 10px;
}

.ProfileRow .profile_img .profile_imgRadius {
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 38px;
    margin-bottom: 30px;
}

.ProfileRow .profile_img {
    text-align: center;
}

.ProfileRow .profile_img img {
    width: 150px;
    height: 150px;
}

.ProfileRow .profile_img p.big {
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #000;
}

.ProfileRow .withdrwaBtnn>button {
    background-color: #0B30A9;
    border: none;
    outline: none;
    color: #ffffff;
    border-radius: 50px;
    font-size: 18px;
    padding: 10px 30px;
}


/* Modal Css Starts ends */


/* AppointmentDescriptionModal and are you sure modal css strars */

.appointmentDespModalBody,
.areYouSureModal_Body {
    text-align: center;
}

.appointmentDesrModal_inner h4,
.areYouSureModal_head h4 {
    font-size: 30px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #000000;
}

.appointmentDesrModal_inner .appointmentDesrModal_imgOuter {
    width: 150px;
    height: 150px;
    border-radius: 30px;
    box-shadow: 0 0px 15px -3px rgb(0 0 0 / 7%), 0 0px 6px -2px rgb(0 0 0 / 5%);
    margin: 30px auto;
    overflow: hidden;
}

.appointmentDesrModal_inner .appointmentDesrModal_imgOuter img {
    width: 100%;
    height: 100%
}

.paraTimingDes {
    font-size: 15px;
}

.appointmentDesrModal_inner .appointmentDespModal_text p.big,
.areYouSureModal_text p {
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #000000;
}

.small-modal-x .modal-content {
    width: 50%;
    margin: 0 auto;
}

.apppointmentDest_closeBtn>button,
.areYouSureBtn>button {
    padding: 12px 73px;
    background-color: #ef464b;
    color: white;
    border-radius: 30px;
    width: unset;
}

.areYouSureBtn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.areYouSureModal .modal-content {
    width: 50%;
    margin: 0 auto;
}


/* AppointmentDescriptionModal and are you sure modal css ends */


/* payemet method */
.add-payment-method-modal form {
    padding: 10px;
  }
  .add-payment-method-modal form .field-box {
    padding: 2% 1%;
    border: 1px solid lightgrey;
  }
  .add-payment-method-modal form .custom-btn {
    margin-top: 5%;
  }
  .add-payment-method-modal form .custom-btn:disabled {
    margin-top: 4%;
    padding: 10px 20px !important;
    border-radius: 8px;
    font-size: 13px !important;
    border: 2px solid #ffffff;
    background-color: lightgrey !important;
    color: #111111;
  }
  .buy-credits-section .buy-credits {
    margin: 5% 0px;
  }
  
  .buy-credits-section .buy-credits-box {
    box-shadow: 0px 2px 5px 1px rgb(206, 205, 205);
    padding: 5% 4%;
    border-radius: 15px;
  }
  .buy-credits-section .buy-credits-box h5 {
    margin-bottom: 3%;
  }
  
  .buy-credits-section .buy-credits-box .amount-box {
    border: 1px solid rgb(206, 205, 205);
    width: 100%;
    padding: 5% 0px;
    margin-bottom: 5%;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
  }
  
  .buy-credits-section .buy-credits-box .amount-box-selected {
    border: 1px solid #feb50f;
    color: #feb50f;
  }
  
  .buy-credits-section .buy-credits-box .amount-input {
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgb(206, 205, 205);
    padding: 5% 10px;
    outline: none;
  }
  .buy-credits-section .buy-credits-box .amount-input:focus {
    border: 1px solid #feb50f;
  }
  
  .buy-credits-section .buy-credits .total-credits-box {
    box-shadow: 0px 2px 5px 1px rgb(206, 205, 205);
    padding: 5% 4%;
    border-radius: 15px;
  }
  
  .buy-credits-section .buy-credits .total-credits-box h6 {
    text-align: center;
    font-size: 20px;
  }
  .buy-credits-section .buy-credits .total-credits-box .total-amount {
    text-align: center;
    font-size: 18px;
    border-bottom: 1px solid #feb50f;
    padding-bottom: 2%;
  }
  
  .buy-credits-section .buy-credits .total-credits-box .label-with-amount {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2%;
  }
  .border-bottom-yellow {
    border-bottom: 1px solid #feb50f;
  }
  .buy-credits-section .buy-credits .total-credits-box .pay-btn {
    width: 100%;
    margin-top: 5%;
  }
  .buy-credits-section .buy-credits .total-credits-box .pay-btn:disabled {
    background-color: rgb(207, 206, 206);
    border: 1px solid lightgray;
  }
  .buy-credits-section .buy-credits .payment-methods-box {
    margin-top: 5%;
    box-shadow: 0px 2px 5px 1px rgb(206, 205, 205);
    padding: 5% 0%;
    border-radius: 15px;
  }
  .buy-credits-section .buy-credits .payment-methods-box h5 {
    font-size: 25px;
    padding-left: 5%;
    border-bottom: 1px solid #feb50f;
    padding-bottom: 2%;
  }
  .buy-credits-section .buy-credits .payment-methods-box .cards-box {
    padding: 0px 4%;
  }
  
  .buy-credits-section
    .buy-credits
    .payment-methods-box
    .cards-box
    .payment-method-card {
    display: flex;
    justify-content: space-between;
    padding: 3% 0px;
  }
  .buy-credits-section
    .buy-credits
    .payment-methods-box
    .cards-box
    .payment-method-card.payment-method-card-select {
    border: 1px solid #feb50f;
    border-radius: 8px;
  }
  .buy-credits-section
    .buy-credits
    .payment-methods-box
    .cards-box
    .payment-method-card
    .card-icon {
    width: 60px;
    height: 40px;
    margin-right: 10px;
  }
  
  .buy-credits-section
    .buy-credits
    .payment-methods-box
    .cards-box
    .payment-method-card
    .icon {
    font-size: 20px;
    margin-right: 5px;
  }
  .buy-credits-section
    .buy-credits
    .payment-methods-box
    .cards-box
    .payment-method-card
    .img-with-title {
    display: flex;
  }
  .buy-credits-section
    .buy-credits
    .payment-methods-box
    .cards-box
    .payment-method-card
    .img-with-title
    .title-with-card-key {
    display: flex;
    flex-direction: column;
  }
  
  .buy-credits-section
    .buy-credits
    .payment-methods-box
    .cards-box
    .payment-method-card
    .img-with-title
    .title-with-card-key
    .card-key {
    font-size: 14px;
  }
  
  .buy-credits-section
    .buy-credits
    .payment-methods-box
    .cards-box
    .payment-method-card
    .img-with-title
    .title-with-card-key
    .title {
    font-size: 18px;
  }
  
  /* Subscription Card */
  .buy-credits-section .buy-credits .package-subscription-card {
    width: 100%;
    border: 1px solid rgb(206, 205, 205);
    border-radius: 8px;
    padding: 5% 0;
  }
  .buy-credits-section .buy-credits .package-subscription-card.selected-card {
    border: 1px solid #feb50f;
  }
  .buy-credits-section .buy-credits .package-subscription-card h5 {
    text-align: center;
    font-family: 500;
  }
  .buy-credits-section .buy-credits .package-subscription-card ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }
  .buy-credits-section .buy-credits .package-subscription-card ul li {
    padding-bottom: 2%;
  }
  
  .buy-credits-section .buy-credits .package-subscription-card .price {
    border-top: 1px solid rgb(206, 205, 205);
    text-align: center;
    margin-top: 5%;
    padding-top: 4%;
    font-size: 25px;
  }
  
  .buy-credits-section .buy-credits .package-subscription-card .price span {
    font-size: 20px;
    color: #393436;
  }
  .buy-credits-section .buy-credits .package-subscription-card .choose-btn-box {
    display: flex;
    justify-content: center;
  }
  .buy-credits-section
    .buy-credits
    .package-subscription-card
    .choose-btn-box
    .custom-btn {
    padding: 7px 20px;
  }
  
  .add-payment-method-modal form .custom-btn {
    margin-top: 5%;
    padding: 10px 20px;
}
  
.are-you-sure-modal {
}

.are-you-sure-modal .modal-box {
  /* border: none;
  margin-top: 12%;
    margin-bottom: 5%; */
    border: none;
    /* width: 80%; */
    margin: auto;
    margin-top: 10%;
    /* margin-bottom: 10%; */
}
.modalbox2{
   margin-bottom: 10% !important;
   width: 80% !important;
}
.are-you-sure-modal .modal-box h5 {
  margin-bottom: 5%;
  text-align: center;
  font-size: 18px;
}
.are-you-sure-modal .modal-box .btns-box {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}

.are-you-sure-modal .modal-box .btns-box button {
  outline: none;
  border: none;
}

.are-you-sure-modal .modal-box .btns-box .no-btn {
  background-color: #1CB735;
  color: #ffffff;
  border-radius: 8px;
  font-size: 18px;
  padding: 8px 30px;
}
.are-you-sure-modal .modal-box .btns-box .no-btn:disabled{
  background-color: grey !important;
}
.are-you-sure-modal .modal-box .btns-box .yes-btn:disabled{
  background-color: grey !important;
}
.are-you-sure-modal .modal-box .btns-box .yes-btn {
  background-color: #1CB735;
  color: #ffffff;
  border-radius: 8px;
  font-size: 18px;
  padding: 8px 30px;
  margin-left: 3%;
}

.modal-close-icon {
  float: right;
  padding: 3px 5px;
  background-color: #1CB735;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
}
.modal-close-icon .icon {
  width: 25px;
  height: 25px;
}
.addCard_btn {
  padding: 10px 30px;
  margin-top: 30px;
}

.addCard_btn :disabled {
  background-color: #7d7d7d !important ;
}
