* {
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    line-height: 1.2;
    overflow-x: hidden !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: "Poppins Regular";
    src: local("Poppins Regular"), url("./assets/fonts/poppins/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins Bold";
    src: local("Poppins Bold"), url("./assets/fonts/poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins SemiBold";
    src: local("Poppins SemiBold"), url("./assets/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Poppins Medium";
    src: local("Poppins Medium"), url("./assets/fonts/poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Roboto-Bold";
    src: url("./assets/fonts/Roboto/Roboto-Bold.ttf");
    font-weight: normal;
}

a {
    font-size: 18px;
    font-family: "Poppins Regular";
}


/* all heading */

h1 {
    font-family: "Poppins Bold";
    font-size: 60px;
}

h2 {
    font-family: "Poppins SemiBold";
    font-size: 40px;
}

h3 {
    font-family: "Poppins Medium";
    font-size: 35px;
}

h3.small {
    font-family: "Poppins Medium";
    font-size: 30px;
}

h4 {
    font-family: "Poppins Medium";
    font-size: 26px;
}

h5 {
    font-family: "Poppins Medium";
    font-size: 20px;
}

h6 {
    font-family: "Poppins Medium";
    font-size: 18px;
}

p span select option {
    font-family: "Poppins Regular";
    line-height: 1.2;
}


/* All para */

p.big {
    font-family: "Poppins Regular";
    font-size: 20px;
    line-height: 1.2;
}

p.mid {
    font-family: "Poppins Regular";
    font-size: 18px;
    line-height: 1.5;
}

p.small {
    font-family: "Poppins Regular";
    font-size: 16px;
    line-height: 1.2;
}

p.xsmall {
    font-family: "Poppins Regular";
    font-size: 14px;
}

table {
    font-family: "Poppins Regular";
}

li a {
    font-family: "Poppins Regular";
    font-size: 18px;
}


/* All button */

button {
    font-family: "Poppins Regular";
    font-size: 15px;
    background-color: #1CB735;
    color: #ffff;
    border-radius: 5px;
    border: none;
    outline: none;
}

input {
    font-family: "Poppins Regular";
    font-size: 20px;
}

.navbar-nav {
    font-family: "Poppins Regular";
    font-size: 18px;
}

.form-check-label {
    font-family: "Poppins Regular";
    font-size: 20px;
}

.green {
    color: #1CB735;
}

.chart {
    width: 500px !important;
    height: 400px !important;
    border: 1px solid;
}

.navbar-light .navbar-nav .nav-link {
    padding: 16px;
    color: #A8A8A8;

    display: flex;
    align-items: center;
}

.table tr td {
    font-family: "Poppins Regular";
}

@media screen and (max-width:1440px) {
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 30px;
    }
    h3 {
        font-size: 24px;
    }
    h4 {
        font-size: 22px;
    }
    h5 {
        font-size: 18px;
    }
    p.big {
        font-size: 16px;
    }
    p.mid {
        font-size: 14px;
    }
    p.small {
        font-size: 12px;
    }
    p.xsmall {
        font-size: 10px;
    }
    li a,
    button,
    input,
    .navbar-nav {
        font-size: 14px;
    }
    .navbar-light .navbar-nav .nav-link {
        padding: 10px;
    }
}

.des_x  h1 , .des_x h2 , .des_x h3 , .des_x h4 , .des_x h5 , .des_x h6 , .des_x p , .des_x span , .des_x a , .des_x li , .des_x b , .des_x strong {
    color: #8f8f8f !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
}
.new_x h1 , .new_x h2 , .new_x h3 , .new_x h4 , .new_x h5 , .new_x h6 , .new_x p , .new_x span , .new_x a , .new_x li , .new_x b , .new_x strong{
    color: black !important;
}
.body-loader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(175, 236, 182 , 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0px 10px 30px 0 rgba(43, 45, 66, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    z-index: 9999;
}
.card_div {
    display: flex;
    justify-content: center;
    box-shadow: 0 0px 15px -3px rgb(0 0 0 / 7%), 0 0px 6px -2px rgb(0 0 0 / 5%);
    border-radius: 20px;
    padding: 36px 28px;
    background: #fff;
    min-height: 290px;
}
.bg-success {
    background-color: #1CB735 !important;
}