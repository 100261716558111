.container {
  display: flex;
  flex-direction: column;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone p {
  margin-bottom: 8px;
}

.dropzone > em {
  margin-bottom: 8px;
}

.selectedFilesContainer {
  margin-top: 10px;
}

.fileTypeContainerPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
}

.fileTypeContainerPreview svg {
  color: #1cb735;
  font-size: 22px;
  margin-bottom: 8px;
}

.fileTypeContainerPreview p {
  margin-bottom: 0;
  font-size: 13px;
  color: #ccc;
  text-align: center;
}
