.support_mainContainer {
    width: 100%;
  }
  
  .support_Head {
    height: 569px;
    background: linear-gradient(90deg, #4bec66 0%, #23aa7b 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .support_Head h1 {
    color: white;
    font-weight: 900;
  }
  
  .support_Head p {
    margin-top: 10px;
    color: white;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }
  
  .support_body {
    /* width: 80%; */
    margin: 30px;
  }
  
  .support_introduction {
    color: #1CB735;
  }
  
  .mg-top {
    margin-top: 130px;
  }
  
  .support_Heading {
    margin-top: 30px;
  }
  
  .support_Div h1 {
    color: #1CB735;
    font-weight: 900;
  }
  
  .support_Div h2 {
    color: #1CB735 !important;
    font-weight: 900 !important;
    font-size: 36px !important;
  }
  .cus_b_support2 h3{
    margin-top: 0px !important;
  
  }
  .support_Div h3 {
    color: #1CB735;
    font-weight: 900;
    margin-top: 130px;
  }
  
  .support_Div h4 {
    color: #1CB735;
    font-weight: 900;
  }
  
  .support_Div h5 {
    color: #1CB735;
    font-weight: 900;
  }
  
  .support_Div h6 {
    color: #1CB735;
    font-weight: 900;
  }
  